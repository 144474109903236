import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import './AddEmployee.css';
import UserImage from "../../../../assets/Images/user_Image.png"
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getTodayDate } from "@mui/x-date-pickers/internals";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Close, Save } from "@mui/icons-material";
import { commanServicer } from "../../../Commen_Services/common.services";
import customToast from "../../../Commen_Component/Toast/CustomToast";
import PersonalInfo from "./PersonalInfo";
import Popup from "reactjs-popup";
import Identification from "./Identification";
import WorkInformation from "./WorkInformation";
import BankDetails from "./BankDetails";
import { EmployeeMasterService } from "../../../Commen_Services/EmployeeMaster.service";
import { DocType } from "../../../../_enums/enum.services";
import { red } from "@mui/material/colors";
import { EmployeeBankDetails, EmployeeIdentification, EmployeeInfo, EmployeePersonalInfo, EmployeeRequired, EmployeeWorkInfo } from "./EmployeeModel";




interface Tab {
    id: number;
    title: string;
}




const AddEmployee = () => {

    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
    const [activeTab, setActiveTab] = useState<number>(1);
    const [inputs, setInputs] = useState<any>({ isActive: true });
    const [inputForParent, setInputsforParent] = useState<EmployeeRequired>({ employeeCode: '',
    employeeName: '',
    loginRights: 0,
    work_Mobile: '',
    work_Email: '',
    joining_Date: '',
    total_Experience: '',
    isActive: false
    });

    const [inputsForBankDetail, setInputsForBankDetail] = useState<EmployeeBankDetails>({ nameOnBankAccount: '',
        bankName: '',
        back_Account_No: '',
        ifsC_Code: ''
        });

    const [inputsForIdentification, setInputsForIdentification] = useState<EmployeeIdentification>({ nameOnAdharcard: '',
        adharCardNo: '',
        nameOnPanCard: '',
        pancardNo: '',
        documentDetails: null
    });

    const [inputsForPersonalInfo, setInputsForPersonalInfo] = useState<EmployeePersonalInfo>({
    personal_Contact: '',
    personal_Email: '',
    birth_Date: '',
    gender: null,
    bloodGroup: null,
    maritalStatus: null,
    anniversaryDate: '',
    emergency_Contact_P_Name: '',
    emp_Relationship: '',
    emergency_Email: '',
    emergency_Contact: '',
    streetAddress: '',
    pinCodeId: null,
    state_Id: null,
    city_Id: null,
    highest_Education: '',
    institue_Name: '',
    });
    
    
    const [inputForWorkInfor, setInputsForWorkInfo] = useState<EmployeeWorkInfo>({
        previous_Company_Name: '',
        previous_Job_Position: '',
        previous_Job_Start_date: '', 
        previous_Job_End_date: '',
        previous_Job_Location: '',
        documentDetails: null,
    });

    const [inputsForchild, setInputsforChild] = useState<any>({});
    const [isViewData, setIsViewdata] = useState(false);
    const [globalError, setGlobalError] = useState<EmployeeInfo>({
        employeeCode: '',
        employeeName: '',
        loginRights: '',
        joining_Date: '',
        work_Mobile: '',
        work_Email: '',
        total_Experience: '',
        ProfileImage: '',
        birth_Date: '',
        personal_Email: '',
        bloodGroup: '',
        gender: '',
        maritalStatus: '',
        anniversaryDate: '',
        state_Id: '',
        city_Id: '',
        pinCodeId: '',
        streetAddress: '',
        emergency_Contact_P_Name: '',
        emergency_Contact: '',
        emp_Relationship: '',
        emergency_Email: '',
        highest_Education: '',
        previous_Company_Name : '',
        previous_Job_Position: '',
        previous_Job_Location: '',
        previous_Job_Start_date: '',
        previous_Job_End_date: '',
        nameOnAdharcard: '',
        adharCardNo: '',
        nameOnPanCard: '',
        pancardNo: '',
        nameOnBankAccount: '',
        bankName: '',
        back_Account_No: '',
        ifsC_Code: '',
        personal_Contact: '',
        AadharBack: '',
        AadharFront: '',
        PANImage: '',
        workExperienceDoc: '',
        institue_Name: ''

    });
    const [secondsforEdit, setSecondsforEdit] = React.useState<number>(0);
    
    const [error, setError] = useState({
        employeeCode: '',
        employeeName: '',
        loginRights: '',
        work_Mobile: '',
        work_Email: '',
        total_Experience: '',
        joining_Date: '',
        ProfileImage: '',
    });
    const navigate = useNavigate();

    const handleDatafromChild = (data: any) => {
        for (let key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const value = data[key];
                setInputs((values: any) => ({ ...values, [key]: data[key] }))
            }
        }
    }

    const handleDatafromPersonalInfo = (data: any) => {

        const checkDiff = () => {
            return (inputsForPersonalInfo.personal_Contact != data.personal_Contact   ||
                inputsForPersonalInfo.personal_Email != data.personal_Email  ||
                inputsForPersonalInfo.birth_Date != data.birth_Date  ||
                inputsForPersonalInfo.gender != data.gender  ||
                inputsForPersonalInfo.bloodGroup != data.bloodGroup  ||
                inputsForPersonalInfo.maritalStatus != data.maritalStatus  ||
                inputsForPersonalInfo.anniversaryDate != data.anniversaryDate  ||
                inputsForPersonalInfo.emergency_Contact_P_Name != data.emergency_Contact_P_Name  ||
                inputsForPersonalInfo.emp_Relationship != data.emp_Relationship  ||
                inputsForPersonalInfo.emergency_Email != data.emergency_Email  ||
                inputsForPersonalInfo.emergency_Contact != data.emergency_Contact  ||
                inputsForPersonalInfo.streetAddress != data.streetAddress  ||
                inputsForPersonalInfo.pinCodeId != data.pinCodeId  ||
                inputsForPersonalInfo.state_Id != data.state_Id  ||
                inputsForPersonalInfo.city_Id != data.city_Id  ||
                inputsForPersonalInfo.highest_Education != data.highest_Education  ||
                inputsForPersonalInfo.institue_Name != data.institue_Name );
        }

        const checkDiff2 = () => {
            return (data.personal_Contact !=  '' ||
                data.personal_Email != ''  ||
                data.birth_Date !=  '' ||
                data.gender !=   null ||
                data.bloodGroup !=  null ||
                data.maritalStatus !=   null ||
                data.anniversaryDate !=  '' ||
                data.emergency_Contact_P_Name !=  '' ||
                data.emp_Relationship !=  '' ||
                data.emergency_Email !=  '' ||
                data.emergency_Contact !=  '' ||
                data.streetAddress !=  '' ||
                data.pinCodeId !=  null ||
                data.state_Id !=  null ||
                data.city_Id !=  null ||
                data.highest_Education !=  '' ||
                data.institue_Name != '' );
        }
        
        if(checkDiff() && checkDiff2())
        {
            setInputsForPersonalInfo(data);
            setInputs((values: any) => ({ ...values, ...data }));
        }
        // for (let key in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, key)) {
        //         const value = data[key];
        //         setInputsForPersonalInfo((values: any) => ({ ...values, [key]: data[key] }))
        //     }
        // }
        
        // for (let key in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, key)) {
        //         const value = data[key];
        //         setInputs((values: any) => ({ ...values, [key]: data[key] }))
        //     }
        // }
    }

    const handleDatafromWorkInfo = (data: any) => {

        const checkDiff = () => {
            return (inputForWorkInfor.previous_Company_Name != data.previous_Company_Name   ||
                inputForWorkInfor.previous_Job_End_date != data.previous_Job_End_date  ||
                inputForWorkInfor.previous_Job_Start_date != data.previous_Job_Start_date  ||
                inputForWorkInfor.previous_Job_Location != data.previous_Job_Location  ||
                inputForWorkInfor.previous_Job_Position != data.previous_Job_Position );
        }

        const checkDiff2 = () => {
            return (data.previous_Company_Name  !=  '' ||
                data.previous_Job_End_date != ''  ||
                data.previous_Job_Location !=  '' ||
                data.previous_Job_Position !=   '' ||
                data.previous_Job_Start_date !=  '' );
        }       

        if(checkDiff() && checkDiff2())
        {
            setInputsForWorkInfo(data);
            setInputs((values: any) => ({ ...values, ...data }));
        }
    }

    const handleDatafromBankInfo = (data: any) => {

        const checkDiff = () => {
            return (inputsForBankDetail.back_Account_No != data.back_Account_No   ||
                inputsForBankDetail.bankName != data.bankName  ||
                inputsForBankDetail.ifsC_Code != data.ifsC_Code  ||
                inputsForBankDetail.nameOnBankAccount != data.nameOnBankAccount   );
        }

        const checkDiff2 = () => {
            return (data.back_Account_No  !=  '' ||
                data.bankName != ''  ||
                data.ifsC_Code !=  '' ||
                data.nameOnBankAccount !=   ''
            );
        }
        
        if(checkDiff() && checkDiff2())
        {
            setInputsForBankDetail(data);
            setInputs((values: any) => ({ ...values, ...data }));
        }
    }

    const handleDatafromIdentificationInfo = (data: any) => {

        const checkDiff = () => {
            return (inputsForIdentification.adharCardNo != data.adharCardNo   ||
                inputsForIdentification.nameOnAdharcard != data.nameOnAdharcard  ||
                inputsForIdentification.nameOnPanCard != data.nameOnPanCard  ||
                inputsForIdentification.pancardNo != data.pancardNo   );
        }

        const checkDiff2 = () => {
            return (data.adharCardNo  !=  '' ||
                data.nameOnAdharcard != ''  ||
                data.nameOnPanCard !=  '' ||
                data.pancardNo !=   ''
            );
        }
        
        if(checkDiff() && checkDiff2())
        {
            setInputsForIdentification(data);
            setInputs((values: any) => ({ ...values, ...data }));
        }
    }

    



    const handleErrorfromChild = (data: EmployeeInfo) => {
        // for (let key in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, key)) {
        //         const value = data[key];
        //         setGlobalError((values: any) => ({ ...values, [key]: data[key] }))
        //     }
        // }
        if(globalError != data)
            {
                setGlobalError(data);
            }

    }
   
    useEffect(() => {

        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
        };

        let isDifferent = areObjectsEqual(inputs, inputsForchild);
        if (!isDifferent) {
            
            setInputsforChild(inputs);

        }
        
    }, [inputs])



    const initialTabs: Tab[] = [
        // { id: 1, title: 'Personal Information', content: <PersonalInfo sendDataToParent={handleDatafromChild} formData={inputs} /> },
        { id: 1, title: 'Personal Information' },
        { id: 2, title: 'Identification Information' },
        { id: 3, title: 'Work Information' },
        { id: 4, title: 'Bank Details' }
    ];

    const { pageId } = useParams<{ pageId: string }>();
    const { action } = useParams<{ action: string }>();
    const [selectedImage, setSelectedImage] = useState<any>(null);

    const [documentList, setDocumentList] = useState<any[]>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [secondsforActive, setSecondsforActive] = React.useState<number>(0);
    const [countEdit, setCountedit] = React.useState(0);
    const [countActive, setCountActive] = React.useState(0);

    useEffect(() => {

        const fetchEmployeeData = async (id: any) => {
            
            const EmployeeData = await EmployeeMasterService.getEmployeeById(id);            
            setInputs(EmployeeData.data);
            setInputsforParent({
                employeeCode: EmployeeData.data.employeeCode,
                employeeName: EmployeeData.data.employeeName,
                loginRights: EmployeeData.data.loginRights,
                work_Mobile: EmployeeData.data.work_Mobile,
                work_Email: EmployeeData.data.work_Email,
                joining_Date: EmployeeData.data.joining_Date,
                total_Experience: !!EmployeeData.data.total_Experience ? EmployeeData.data.total_Experience : 0,
                isActive: EmployeeData.data.isActive
            });

            setInputsForBankDetail({
                nameOnBankAccount: EmployeeData.data.nameOnBankAccount,
                bankName: EmployeeData.data.bankName,
                back_Account_No: EmployeeData.data.back_Account_No,
                ifsC_Code: EmployeeData.data.ifsC_Code
            });

            setInputsForIdentification({
                nameOnAdharcard: EmployeeData.data.nameOnAdharcard,
                adharCardNo: EmployeeData.data.adharCardNo,
                nameOnPanCard: EmployeeData.data.nameOnPanCard,
                pancardNo: EmployeeData.data.pancardNo,
                documentDetails: EmployeeData.data.documentDetails,
            });

            setInputsForPersonalInfo({
                personal_Contact: EmployeeData.data.personal_Contact,
                personal_Email: EmployeeData.data.personal_Email,
                birth_Date: EmployeeData.data.birth_Date,
                gender: EmployeeData.data.gender,
                bloodGroup: EmployeeData.data.bloodGroup,
                maritalStatus: EmployeeData.data.maritalStatus,
                anniversaryDate: EmployeeData.data.anniversaryDate,
                emergency_Contact_P_Name: EmployeeData.data.emergency_Contact_P_Name,
                emp_Relationship: EmployeeData.data.emp_Relationship,
                emergency_Email: EmployeeData.data.emergency_Email,
                emergency_Contact: EmployeeData.data.emergency_Contact,
                streetAddress: EmployeeData.data.streetAddress,
                pinCodeId: EmployeeData.data.pinCodeId,
                state_Id: EmployeeData.data.state_Id,
                city_Id: EmployeeData.data.city_Id,
                highest_Education: EmployeeData.data.highest_Education,
                institue_Name: EmployeeData.data.institue_Name,
            });

            setInputsForWorkInfo({
                previous_Company_Name: EmployeeData.data.previous_Company_Name,
                previous_Job_Position: EmployeeData.data.previous_Job_Position,
                previous_Job_Start_date: EmployeeData.data.previous_Job_Start_date,
                previous_Job_End_date: EmployeeData.data.previous_Job_End_date,
                previous_Job_Location: EmployeeData.data.previous_Job_Location,
                documentDetails: EmployeeData.data.documentDetails,
            });

            setisActivetoggle(EmployeeData.data.isActive)
            if (EmployeeData.data.joining_Date !== null) {
                const formattedDate = EmployeeData.data.joining_Date.split('T')[0];
                setStartDate(formattedDate);
            }
            if (EmployeeData.data.loginRights !== null) {
                setSelectedRight(EmployeeData.data.loginRights)
            }
            if (EmployeeData.data.documentDetails !== null) {
                let documents = EmployeeData.data.documentDetails;
                documents.map((field: any) => {
                    if (field.documentTypeId === DocType.EmployeeProfile) {
                        const file = field.documentPath;
                        setSelectedImage(file);
                    }
                });
                

                
            }
        }


        if (pageId != undefined && Number(pageId) > 0) {
            fetchEmployeeData(pageId);
        }

    }, [pageId])

    React.useEffect(() => {
        const interval = setInterval(() => {
          if (secondsforActive > 0) {
            setSecondsforActive(secondsforActive - 1);
          }
        }, 1000);
    
        if (secondsforActive === 0) {
          // console.log("view");
    
          setCountActive(0);
        }
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [secondsforActive]);


    useEffect(() => {
        if(action === "view")
        {
            setIsViewdata(true);
        }

    },[action])

    React.useEffect(() => {
        const interval = setInterval(() => {
          if (secondsforEdit > 0) {
            setSecondsforEdit(secondsforEdit - 1);
          }
        }, 1000);
    
        if (secondsforEdit === 0) {
          setCountedit(0);
        }
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [secondsforEdit]);

    const [selectedRight, setSelectedRight] = useState(null);
    const [options, setOptions] = useState([{ OptionID: 1, OptionName: 'Mobile' }, { OptionID: 2, OptionName: 'Web Portal' }, { OptionID: 3, OptionName: 'Both' }]);
    const [isActiveToggle, setisActivetoggle] = useState(true);
    const [startDate, setStartDate] = useState<any>("");
    const [value, setValue] = React.useState('1');
    const [tabs, setTabs] = useState<Tab[]>(initialTabs);


    const changeTab = (tabId: number) => {
        setActiveTab(tabId);
    };


    const handleProfileImageUpload = (e: any, documentType: any) => {

        const file = e.target.files[0];
        if (!ALLOWED_TYPES.includes(file.type)) {
            setError((prevErrors) => ({ ...prevErrors, ProfileImage: "Invalid file type. Only JPEG, PNG, and GIF are allowed." }));
            setGlobalError((prevErrors: any) => ({ ...prevErrors, ProfileImage: "Invalid file type. Only JPEG, PNG, and GIF are allowed." }));
            
            return;
        }
        else
        {
            setError((prevErrors) => ({ ...prevErrors, ProfileImage: "" }));
            setGlobalError((prevErrors: any) => ({ ...prevErrors, ProfileImage: "" }));

            setSelectedImage(URL.createObjectURL(file));
            setDocumentList((values: any) => ([...values, { fileData: file, docType: documentType, documentName: e.target.name }]));
        }


    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs,newInputstoSend;
        newInputstoSend = { ...inputs, [name]: value };
        newInputs = { ...inputForParent, [name]: value };
        
        //let newError = { ...error };
        let newError = { ...globalError};

        if (name === 'employeeCode') {

            if (value !== "" && !/^[a-zA-Z0-9]{2,100}$/.test(value)) {
                newError.employeeCode = "Please enter a valid employee Code";
            }
            else if(value.trim() == "" && value.length > 0)
            {
                newError.employeeCode = "Please enter a valid employee Code";
            }
            else {
                newError.employeeCode = '';
            }
        }
        if (name === 'employeeName') {

           // if (value !== "" &&  !/^[\w\s!@#$%^&*(),.?":{}|<>a-zA-Z0-9 ]{2,100}$/.test(value)) {
            if (value.trim() !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.employeeName = "Please enter a valid employee name";
            }
            else if(value.trim() == "" && value.length > 0)
            {
                newError.employeeName = "Please enter a valid employee name";
            }
            else {
                newError.employeeName = '';
            }
        }
        if (name === 'total_Experience') {
            
            // if(value === '')
            // {
            //     newInputs = { ...inputs, total_Experience: '' }

            // }
            // else
            // {
            //     newInputs = { ...inputs, total_Experience: Number(value) }

            // }
        if(value !== '')
        {
            if ( parseInt(value) >= 100 || Number.isNaN( parseInt(value))) {
                newError.total_Experience = "Please enter a valid Experience";
            }
            else if ( parseInt(value) < 0) {
                newError.total_Experience = "Please enter a valid Experience";
            }
            else {
                newError.total_Experience = '';
                //newInputs = { ...inputs, total_Experience: Number(value) }
                newInputs = { ...inputs, total_Experience: Number(value) }
                newInputstoSend = { ...inputs, total_Experience: Number(value) }

            }
        }
        else
        {
            //newInputs = { ...inputs, total_Experience: '' }
            newInputs = { ...inputs, total_Experience: '' }
            newInputstoSend = { ...inputs, total_Experience: '' }

            newError.total_Experience = '';
        }
           
        }
        if(name === 'work_Mobile') {
            if(value !== "" && !/^[0-9]{10}$/.test(value))
            {
                newError.work_Mobile = "Please enter a valid Mobile Number";                
            }
            else
            {
                newError.work_Mobile = '';
            }
        }
        if(name === 'work_Email')
        {
            if(value !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
            {
                newError.work_Email = "Please enter a valid Email";
            }
            else
            {
                newError.work_Email = '';
            }
        }
        setInputs(newInputstoSend);
        setInputsforParent(newInputs);
        setError(newError);
        setGlobalError(newError);

    };
    const handleFocus = (fieldName: any) => {

        if (fieldName == 'employeeCode') {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a valid employee Code" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, [fieldName]: "Please enter a valid employee Code" }));

            }
        }
        else if (fieldName == 'employeeName') {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a valid employee name" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, [fieldName]: "Please enter a valid employee name" }));

            }
        }
        else if (fieldName == 'loginRights') {

            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please select Login Rights" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, [fieldName]: "Please select Login Rights" }));

            }

        }
        else if (fieldName == 'joining_Date') {

            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a valid start date" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, [fieldName]: "Please enter a valid start date" }));

            }

        }

    };

    const handleChangeMUIforLoginRight = (event: any) => {


        const name = event.target.name;
        const value = event.target.value;
        setSelectedRight(value);
        setError((prevErrors) => ({ ...prevErrors, loginRights: '' }));
        setGlobalError((prevErrors: any) => ({ ...prevErrors, loginRights: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))
        setInputsforParent((values: any) => ({ ...values, [name]: value }));


    }

    const handleChangeforIsActive = ((event: any) => {

        const name = event.target.name;
        const value = event.target.checked;
        setisActivetoggle(value);
        setInputs((values: any) => ({ ...values, [name]: value }));
        setInputsforParent((values: any) => ({ ...values, [name]: value }));


    })


    const handleChangeforStartDate = ((event: any) => {

        const name = event.target.name;
        const value = event.target.value;
        //value != "" && setStartDate(value);
        setStartDate(value);
        setInputs((values: any) => ({ ...values, [name]: value }));
        setInputsforParent((values: any) => ({ ...values, [name]: value }));
        if (value > new Date().toISOString().split('T')[0]) {
            setError((prevErrors) => ({ ...prevErrors, joining_Date: 'Please enter a valid start date (not in the future).' }));
            setGlobalError((prevErrors: any) => ({ ...prevErrors, joining_Date: 'Please enter a valid start date (not in the future).' }));

        } else {
            
            setError((prevErrors) => ({ ...prevErrors, joining_Date: '' }));
            setGlobalError((prevErrors: any) => ({ ...prevErrors, joining_Date: '' }));

        }

    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    const isErrorFree = () => {
        if(globalError.employeeCode != "") return false;
        else if(globalError.employeeName != "") return false;
        else if(globalError.loginRights != "") return false;
        else if(globalError.joining_Date != "") return false;
        else if(globalError.work_Mobile != "") return false;
        else if(globalError.work_Email != "") return false;
        else if(globalError.total_Experience != "") return false;
        else if(globalError.ProfileImage != "") return false;
        else if(globalError.birth_Date != "") return false;
        else if(globalError.personal_Email != "") return false;
        else if(globalError.bloodGroup != "") return false;
        else if(globalError.gender != "") return false;
        else if(globalError.maritalStatus != "") return false;
        else if(globalError.anniversaryDate != "") return false;
        else if(globalError.state_Id != "") return false;
        else if(globalError.city_Id != "") return false;
        else if(globalError.pinCodeId != "") return false;
        else if(globalError.streetAddress != "") return false;
        else if(globalError.emergency_Contact_P_Name != "") return false;
        else if(globalError.emergency_Contact != "") return false;
        else if(globalError.emp_Relationship != "") return false;
        else if(globalError.emergency_Email != "") return false;
        else if(globalError.highest_Education != "") return false;
        else if(globalError.previous_Company_Name != "") return false;
        else if(globalError.previous_Job_Position  != "") return false;
        else if(globalError.previous_Job_Location != "") return false;
        else if(globalError.previous_Job_Start_date != "") return false;
        else if(globalError.previous_Job_End_date != "") return false;
        else if(globalError.nameOnAdharcard != "") return false;
        else if(globalError.adharCardNo != "") return false;
        else if(globalError.nameOnPanCard != "") return false;
        else if(globalError.pancardNo != "") return false;
        else if(globalError.nameOnPanCard != "") return false;
        else if(globalError.nameOnBankAccount != "") return false;
        else if(globalError.bankName != "") return false;
        else if(globalError.back_Account_No != "") return false;
        else if(globalError.ifsC_Code != "") return false;
        else if(globalError.personal_Contact != "") return false;
        else if(globalError.AadharBack != "") return false;
        else if(globalError.AadharFront != "") return false;
        else if(globalError.PANImage != "") return false;

        else return true;
    }

    const handelSubmit = async (event: any) => {

        if(Number(pageId) === 0 && inputs.isActive !== true)
        {
            setCountActive(1);
            setSecondsforActive(4);
            if (countActive === 0) {
                customToast.error('The employee should be active');
            }
            return false;
        }
        else
        {

            if (!inputs.employeeCode) {
                setError((prevErrors) => ({ ...prevErrors, employeeCode: "Please enter a valid employee Code" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, employeeCode: "Please enter a valid employee Code" }));
    
            }
            if (!inputs.employeeName || inputs.employeeName.trim() === "") {
                setError((prevErrors) => ({ ...prevErrors, employeeName: "Please enter a valid employee name" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, employeeName: "Please enter a valid employee name" }));
    
            }
            if (!inputs.loginRights) {
                setError((prevErrors) => ({ ...prevErrors, loginRights: "Please Select Login Rights" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, loginRights: "Please Select Login Rights" }));
    
            }
            if (!inputs.joining_Date) {
                setError((prevErrors) => ({ ...prevErrors, joining_Date: "Please enter a valid start date" }));
                setGlobalError((prevErrors: any) => ({ ...prevErrors, joining_Date: "Please enter a valid start date" }));
            }
            
            // if (inputs.employeeCode !== undefined && inputs.employeeName !== undefined && inputs.loginRights !== undefined && inputs.joining_Date !== undefined) {
            if (inputs.employeeCode !== undefined && inputs.employeeCode !== "" && inputs.employeeName !== undefined && inputs.employeeName.trim() !== "" && inputs.loginRights !== undefined && inputs.loginRights  !== "" && inputs.joining_Date !== undefined && inputs.joining_Date !== "") {
                
            // if (error.employeeCode === '' && error.employeeName === "" && error.loginRights === "" && error.joining_Date === "") {            

                
                if(isErrorFree())
                {
                   
                
                    try {
                        let Objlist: any = [];
    
                        // Use Promise.all to await all async operations inside map
                        await Promise.all(documentList.map(async (file1: any) => {
                            let obj2: any = {};
                            let file = file1.fileData;
                            const d2 = new Date();
                            let ImageName = `${d2.getDay()}_${d2.getMonth()}_${d2.getFullYear()}_${d2.getHours()}_${d2.getMinutes()}_${d2.getSeconds()}_${d2.getMilliseconds()}_${file.name}`;
                            let abpath = 'AdminPortal/Employee/';
                            let Val1 = await commanServicer.UploadImage(abpath, ImageName, file);
                            if (Val1) {
                                obj2.documentName = file.name;
                                obj2.documentTypeId = file1.docType;
                                obj2.documentPath = Val1;
                                Objlist.push(obj2);
                            }
                        }));
    
                        
    
                        inputs.documentDetails = Objlist;

                        const filteredInputs = Object.fromEntries(
                            Object.entries(inputs).filter(([key, value]) => value !== "" && value !== null)
                        );

                        const res = await EmployeeMasterService.SaveorUpdateEmployee(filteredInputs);
                        
                        if (res.status) {
                            if(countEdit === 0)
                            {
                                customToast.success(res.message);
                                setCountedit(1);
                                setSecondsforEdit(4);
                            }
                            navigate("/Master/Employee/"+res.data);
                            
                        } else {
                            if(countEdit === 0)
                            {
                                customToast.Warning(res.message);
                                setCountedit(1);
                                setSecondsforEdit(4);
                            }
                        }
                    } catch (error) {
                        console.error("Error in handleSubmit:", error);
                        // Handle errors appropriately
                        customToast.error("Failed to save or update employee.");
                    }
                }
            }
        }
    };

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {

        // setIssave(true);
        // setShowConfirmation(false);
        // handleCancel ? setCancel(false) : setCancel(true);
        navigate("/Report/view/9");

    };

    const handleDocumentDatafromChild = (data: any) => {
        setDocumentList((values: any) => ([...values, data]));

    }




    return (<>
        <div>
            <div className="shadow">
                <div className="bg-line">
                    <span className="bg-head"><FaUser /> &nbsp;{Number(pageId) > 0 && action !== 'view' ? <>Update </> : isViewData ? <>View</> : <>Add </>} Employee</span>
                </div>
                <hr className="colorgraph" />
            </div>
            <div className="Employee_header">
                <div className="Profile_Pic_div">
                    <div className="Profile_img">
                        {selectedImage ? (
                            <img
                                src={selectedImage}
                                width="100"
                                className="rounded-circle imaje"
                                alt="Selected"
                            />
                        ) : (
                            <img
                                src={UserImage}
                                width="100"
                                className="rounded-circle imaje"
                                alt="Default"
                            />
                        )}
                    </div>
                    <div className="prfoile_upload">

                        <label
                            htmlFor="imageupload"
                            className="upload_btn"
                            style={{ fontSize: "14px", justifyContent: 'center' }}
                        >
                            Upload
                        </label>
                        <input
                            id="imageupload"
                            type="file"
                            name="ProfileImage"
                            className="upload-input"
                            readOnly={isViewData}
                            disabled={isViewData}
                            style={{ display: 'none' }}
                            //accept="image/*"
                            accept=".jpg, .png, .pdf"
                            onChange={(event) => handleProfileImageUpload(event, DocType.EmployeeProfile)}
                        />
                    </div>
                    <div>
                        {error.ProfileImage != '' && <span style={{color:'red', fontSize: '9px', fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif'}}>{error.ProfileImage}</span>}
                    </div>
                </div>
                <div className="Profile_dtl">
                    <div className="profile_grid_c">
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            name="employeeCode"
                            //value={inputs["employeeCode"]}
                            value={inputForParent["employeeCode"]}
                            label="Employee Code"
                            onChange={handleChange}
                            //focused={!!inputs.employeeCode}
                            focused={!!inputForParent.employeeCode}
                            onFocus={() => handleFocus("employeeCode")}
                            error={!!error.employeeCode}
                            helperText={error.employeeCode}
                            InputProps={{
                                readOnly: isViewData,
                                style: {
                                    color: '#243C5C',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.employeeCode ? 'red' : undefined
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.employeeCode ? 'red' : undefined
                                },
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.employeeCode ? '9px' : '12px',

                                },
                            }}
                        />
                    </div>
                    <div className="profile_grid_c">
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            name="employeeName"
                            //value={inputs["employeeName"]}
                            value={inputForParent["employeeName"]}
                            label="Employee Name"
                            onChange={handleChange}
                            //focused={!!inputs.employeeName}
                            focused={!!inputForParent.employeeName}
                            onFocus={() => handleFocus("employeeName")}
                            error={!!error.employeeName}
                            helperText={error.employeeName}
                            InputProps={{
                                readOnly: isViewData,
                                style: {
                                    color: '#243C5C',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.employeeName ? 'red' : undefined
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.employeeName ? 'red' : undefined
                                },
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.employeeName ? '9px' : '12px',

                                },
                            }}
                        />
                    </div>
                    <div className="profile_grid_c">
                        <FormControl  focused={!!inputs.loginRights} variant="standard" sx={{ width: 200, minWidth: 120, }}>
                            <InputLabel id="demo-simple-select-standard-label" sx={{
                                fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                            }}>Login Rights</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                autoComplete="inside"
                                name="loginRights"
                                error={!!error.loginRights}
                                value={selectedRight}
                                readOnly={isViewData}
                                onFocus={() => handleFocus("loginRights")}
                                onChange={handleChangeMUIforLoginRight}
                                label="Search c"
                                onClose={(e) => e.preventDefault()}


                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                            >
                                {options.map((item: any) => (
                                    <MenuItem key={item.OptionID} value={item.OptionID}>
                                        {item.OptionName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {error.loginRights ? (
                                <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.loginRights}</FormHelperText>
                            ) : (
                                <FormHelperText sx={{ color: 'white', fontSize: 9 }}>&nbsp;</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div className="Emp_isActive">
                        <label className="Emp_Switch">
                            <input type="checkbox" name="isActive" onChange={handleChangeforIsActive} checked={isActiveToggle} />
                            <span className="Emp_slider"></span>

                        </label>
                        <span>Is Active</span>

                    </div>
                    <div className="profile_grid_c">
                        <TextField
                            sx={{ marginRight: 1,
                                "input::-webkit-inner-spin-button" : {
                                    "-webkit-appearance": "none",
                                    "margin": "0"
                                }
                            }}
                            id="standard-basic"
                            variant="standard"
                            name="work_Mobile"
                            type="number"                          
                            onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                            //value={inputs["work_Mobile"]}
                            value={inputForParent["work_Mobile"]}

                            label="Work Mobile"
                            onChange={handleChange}
                            //focused={!!inputs.work_Mobile}
                            focused={!!inputForParent.work_Mobile}

                            // onFocus={(event: any) => { handleFocus("work_Mobile"); handleChange(event) }}
                            error={!!error.work_Mobile}
                            helperText={error.work_Mobile}


                            InputProps={{
                                readOnly: isViewData,
                                style: {
                                    color: '#243C5C',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.work_Mobile ? 'red' : undefined
                                },
                                onInput: (event: any) => {

                                    const value = event.target.value;
                                    if (value == "") {
                                        //setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value }))
                                        setInputsforParent((values: any) => ({ ...values, [event.target.name]: event.target.value }))

                                    }
                                }                                 
                            }}

                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.work_Mobile ? 'red' : undefined
                                },
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.work_Mobile ? '9px' : '12px',
                                    maxWidth: '210px'

                                },
                            }}
                        />
                    </div>

                    <div className="profile_grid_c">
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            name="work_Email"
                            //value={inputs["work_Email"]}
                            value={inputForParent["work_Email"]}
                            label="Work Email"
                            onChange={handleChange}
                            //focused={!!inputs.work_Email}
                            focused={!!inputForParent.work_Email}
                            onFocus={() => handleFocus("work_Email")}
                            error={!!error.work_Email}
                            helperText={error.work_Email}
                            InputProps={{
                                readOnly: isViewData,
                                style: {
                                    color: '#243C5C',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.work_Email ? 'red' : undefined
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.work_Email ? 'red' : undefined
                                },
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.work_Email ? '9px' : '12px',

                                },
                            }}
                        />

                    </div>
                    <div className="profile_grif_c">
                        <TextField
                            sx={{ marginRight: 1 }}
                            id="standard-basic"
                            variant="standard"
                            name="joining_Date"
                            type="date"
                            value={startDate}
                            label="Joining Date"
                            onChange={handleChangeforStartDate}
                            //focused={!!inputs.joining_Date}
                            focused={!!inputForParent.joining_Date}
                            //focused={true}
                            onFocus={(event: any) => { handleFocus("joining_Date"); handleChange(event) }}
                            error={!!error.joining_Date}
                            helperText={error.joining_Date}
                            InputProps={{
                                inputProps: {
                                    className: startDate ? '' : 'custom-date-input', 
                                  },
                                readOnly: isViewData, 
                                style: {
                                    color: startDate ? '#243C5C' : 'black',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.joining_Date ? 'red' : undefined,
                                
                                },
                                onInput: (event: any) => {

                                    const value = event.target.value;
                                    if (value == "") {
                                        setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value }))
                                        setInputsforParent((values: any) => ({ ...values, [event.target.name]: event.target.value }))

                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.joining_Date ? 'red' : undefined,
                                    
                                    
                                },
                                
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.joining_Date ? '9px' : '12px',
                                    maxWidth: '210px'

                                },
                            }}
                        />



                    </div>
                    <div className="profile_grid_c">
                        <TextField
                            sx={{ marginRight: 1,
                                "input::-webkit-inner-spin-button" : {
                                    "-webkit-appearance": "none",
                                    "margin": "0"
                                }
                             }}
                            id="standard-basic"
                            variant="standard"
                            name="total_Experience"
                            type="number"
                            //value={inputs["total_Experience"]}
                            value={inputForParent["total_Experience"]}

                            onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                            label="Total Experience"
                            onChange={handleChange}
                            //focused={!!inputs.total_Experience}
                            focused={!!inputForParent.total_Experience} 


                            onFocus={(event: any) => { handleFocus("total_Experience"); handleChange(event) }}
                            error={!!error.total_Experience}
                            helperText={error.total_Experience}
                           
                            InputProps={{
                                inputProps: {
                                    step: 3,
                                    min: 1,
                                     
                                },
                                readOnly: isViewData,
                                style: {
                                    color: '#243C5C',
                                    fontSize: '14px',
                                    width: '200px',
                                    borderColor: error.total_Experience ? 'red' : undefined
                                },
                                onInput: (event: any) => {

                                    const value = event.target.value;
                                    if (value == "") {
                                        //setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value }))
                                        setInputsforParent((values: any) => ({ ...values, [event.target.name]: event.target.value }))

                                    }
                                }
                            }}

                            InputLabelProps={{
                                style: {
                                    color: '#243C5C',
                                    fontSize: '12px',
                                    borderColor: error.total_Experience ? 'red' : undefined
                                },
                            }}
                            FormHelperTextProps={{
                                style: {
                                    fontSize: error.total_Experience ? '9px' : '12px',
                                    maxWidth: '210px'

                                },
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className="tabs-container">
                <div className="tabs">
                    {tabs.map(tab => (
                        <div
                            key={tab.id}
                            className={`tab ${tab.id == activeTab ? 'Empactive' : ''}`}
                            // className={`tab ${tab.id === activeTab ? 'active' : ''}`}
                            onClick={() => changeTab(tab.id)}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
                <div className="tab-content">
                    {tabs.map(tab =>
                        tab.id === activeTab ? (
                            <div key={tab.id} className="tab-pane">

                                {/* {tab.content} */}
                                {tab.id == 1 && (<PersonalInfo sendDataToParent={handleDatafromPersonalInfo} formData={inputsForPersonalInfo} sendErrorToParent={handleErrorfromChild} errorData={globalError} isViewData={isViewData} />)}
                                {tab.id == 2 && (<Identification sendDataToParent={handleDatafromIdentificationInfo} formData={inputsForIdentification} sendImagetoParent={handleDocumentDatafromChild} DocumentData={documentList} sendErrorToParent={handleErrorfromChild} errorData={globalError} isViewData={isViewData}/>)}
                                {tab.id == 3 && (<WorkInformation sendDataToParent={handleDatafromWorkInfo} formData={inputForWorkInfor} sendErrorToParent={handleErrorfromChild} errorData={globalError} isViewData={isViewData} sendImagetoParent={handleDocumentDatafromChild} DocumentData={documentList} />)}
                                {tab.id == 4 && (<BankDetails sendDataToParent={handleDatafromBankInfo} formData={inputsForBankDetail} sendErrorToParent={handleErrorfromChild} errorData={globalError} isViewData={isViewData} />)}
                            </div>
                        ) : null
                    )}
                </div>
            </div>
            <div className="Area_btn">
                <button disabled={countEdit === 1} className={`btn123 ${isViewData ? 'inactive' : ''} `} onClick={(event) => { event.stopPropagation(); secondsforEdit === 0 && handelSubmit(event); }} style={{ paddingRight: 12, height: '30px' }}><Save />&nbsp;{Number(pageId) > 0 ? <>Update</>:<>Save</>}</button>&nbsp;
                <button className={`btn123`} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>

                {showConfirmation && (
                    <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                        <div className="confirmation-modal">
                            <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                            <div className="canel_btns">
                                <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                            </div>
                        </div>
                    </Popup>
                )}
            </div>
        </div>


    </>);

}

export default AddEmployee;

