import React, { useEffect, useRef, useState } from 'react';
import './CollectionReport.css';
import { Dialog } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { userServicer } from '../Commen_Services/user.services';
import { PaymentMode } from '../../_enums/enum.services';
import EnhancedTable from '../Commen_Component/EnhancedTable';
import { GrCurrency } from 'react-icons/gr';

interface Invoice {
    invoiceNo: string;
    invoiceDate: string;
    invoiceAmount: number;
    dueDate: string;
    pendingAmount: number;
    collectedAmount: number;
    remainingAmount: number;
}

interface ChequeDetails {
    paymentType: string;
    chequeNo: string;
    amount: number;
    imageUrls?: string;
}

interface CashDetails {
    denomination: string;
    quantity: number;
}

interface UPIDetails {
    transactionId: number;
    amount: number;
    payerAccountName: string;
}

interface NEFTDetails {
    transactionId: number;
    payerAccountName: string;
    bankName: string;
    amount: number;
    ifscCode: string;
    accountNo: string;
    imageUrls?: string;
}

interface CollectionProps {
    collectionID: string;
    employeeName: string;
    collectionDateTime: string;
    paymentMode: string;
    collectedAmount: number;
    customerName: string;
    customerCode: string;
    cheques?: ChequeDetails[];
    cashDetails?: CashDetails[];
    upiDetails?: UPIDetails[];
    neftDetails?: NEFTDetails[];
    invoices: any[];
    pageIdOfTable: number;
    tableHeader: any[];
    open: boolean;
    onClose: () => void;
}

const CollectionDetails: React.FC<CollectionProps> = ({
    collectionID,
    employeeName,
    collectionDateTime,
    paymentMode,
    collectedAmount,
    customerName,
    customerCode,
    cheques,
    cashDetails,
    upiDetails,
    neftDetails,
    invoices,
    pageIdOfTable,
    tableHeader,
    open,
    onClose,
}) => {
    const handlePagination = () => {}
    const handleEditButtondata = (event: any) => {}
    const handleDeleteButtondata = (event: any) => {}

    return (
        <Dialog style={{ height: '90%', padding: 0, marginTop: '1%', borderRadius: '10px 10px 0 0', overflow: 'hidden' }} open={open} onClose={onClose} maxWidth="md" fullWidth>
            <div className="shadow">
                <div className="bg-line">
                    <span className="bg-head1"><LibraryBooksIcon /> &nbsp;Collection Report</span>
                </div>
                <hr className="colorgraph" />
            </div>

            <div className="collection-dialog">
                <div className="employee-info">
                    <p>
                        <strong style={{ color: '#4a148c', marginBottom: 0, fontSize:'14px' }}>Customer Name: </strong>
                        <strong style={{fontSize:'13px'}}>
                            {customerCode ? <span style={{ color: 'blue' }}>[{customerCode}]</span> : null}
                            {customerCode ? ` ${customerName}` : customerName}
                        </strong>
                    </p>

                    <p><strong style={{ color: '#4a148c',fontSize:'14px' }}>Employee Name: </strong> <strong style={{fontSize:'13px'}}>{employeeName}</strong></p>
                </div>
                <div className="cheque-r">
                    <div>
                        <p><strong>Collection ID</strong></p>
                        <p style={{paddingTop: '1px'}}>{collectionID}</p>
                    </div>
                    <div>
                        <p><strong>Collection Date & Time</strong></p>
                        <p style={{paddingTop: '1px'}}>{collectionDateTime}</p>
                    </div>
                    <div>
                        <p><strong>Payment Mode</strong></p>
                        <p style={{paddingTop: '1px'}}>{paymentMode}</p>
                    </div>
                    <div>
                        <p><strong>Collected Amount</strong></p>
                        <p style={{paddingTop: '1px'}}>₹{collectedAmount}</p>
                    </div>
                </div>

                {cheques && cheques.length > 0 && (
                    <div className="cheque-section">
                        <h4>Cheque/DD Details:</h4>
                        <div className="cheque-details">
                            {cheques.map((cheque, index) => (
                                <div key={index} className="cheque-row">
                                    {/* <p>{index + 1}. <strong>{cheque.paymentType}</strong></p> */}
                                    <p><strong> Payment Type: </strong> {cheque.paymentType}</p>
                                    <p><strong> Cheque/DD No: </strong> {cheque.chequeNo}</p>
                                    <p><strong>Amount:</strong> ₹{cheque.amount}</p>
                                    <div className="cheque-images">
                                        <img src={cheque.imageUrls} alt="Cheque" className="cheque-img" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {upiDetails && upiDetails.length > 0 && (
                    <div className="cheque-section">
                        <h4>UPI Details:</h4>
                        <div className="cheque-details">
                            {upiDetails.map((upi, index) => (
                                <div key={index} className="cheque-row">
                                    <p style={{fontSize: '14px'}}><strong >Transaction ID: </strong> {upi.transactionId}</p>
                                    <p style={{fontSize: '14px'}}><strong >Payer UPI: </strong>{upi.payerAccountName}</p>
                                    <p style={{fontSize: '14px'}}><strong >Payment Time : </strong>{collectionDateTime}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {neftDetails && neftDetails.length > 0 && (
                    <div className="cheque-section">
                        <h4>NEFT/RTGS/IMPS Details:</h4>
                        <div className="cheque-details">
                            {neftDetails.map((neft, index) => (
                                <div key={index} className="neft-row">
                                    <div>
                                        <p><strong>Transaction ID:</strong></p>
                                        <p>{neft.transactionId}</p>
                                    </div>
                                    <div>
                                        <p><strong>Payer Name:</strong></p>
                                        <p>{neft.payerAccountName}</p>
                                    </div>
                                    <div>
                                        <p><strong>Bank Name:</strong></p>
                                        <p>{neft.bankName}</p>
                                    </div>
                                    <div>
                                        <p><strong>Account No:</strong></p>
                                        <p>{neft.accountNo}</p>
                                    </div>
                                    <div>
                                        <p><strong>IFSC Code:</strong></p>
                                        <p>{neft.ifscCode}</p>
                                    </div>
                                    <div className="cheque-images">                              
                                        <img src={neft.imageUrls} alt="Cheque" className="cheque-img" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {cashDetails && cashDetails.length > 0 && (
                    <div className="cash-section">
                        <h4>Cash Details:</h4>
                        <div className="cash-grid">
                       
                            {cashDetails.map((cash, index) => (
                                <div key={index} className="cash-row">
                                    <p style={{display: 'flex', flexDirection:'row', textAlign:'center', fontSize: '13px'}}><GrCurrency />&nbsp;{cash.denomination} x {cash.quantity}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="invoice-section">
                    <div>
                        <h4>Invoice Details:</h4>
                        <EnhancedTable pageID={String(pageIdOfTable)} tableHeight={'150px'} buttonSum={[0]} columnData={tableHeader} tableDatafromParent={invoices} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={true} deleteEvent={handleDeleteButtondata} deleteConformationMsg={null} historyQuery={null} historyColumnParameter={null} refreshGrid={null}/>
                    </div>
                    {/* <div style={{ position: 'absolute', bottom: '10px', left: '88%' }}> */}
                    {/* <div style={{margin:0,padding:0,marginLeft:'90%',marginTop:5}}> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', paddingBottom: '15px' }}>
                        {/* <button className='btnSubReport'  onClick={(event) => { event.stopPropagation(); onClose() }} ><span><IoClose /></span>Close</button> */}
                        <button className='btnCollectionReport' onClick={onClose} ><span><IoClose /></span>Close</button>
                    </div>
                </div>

            </div>
        </Dialog>
    );
};

interface propCollectionReport {
    CollectionData: any;
    open: boolean;
    onClose: () => void;
}
const CollectionReport: React.FC<propCollectionReport> = ({ CollectionData, open, onClose }) => {
    console.log(CollectionData, 'CollectionData');
    let data = null;
    let pageId1 = 15;
    const [coldata, setColData] = React.useState<any[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const invoiceRef = useRef<any[]>([0]);

    useEffect(() => {
        const fetchCollectionData = async () => {
            data = await userServicer.CollectionData(CollectionData.collectionId, CollectionData.subModeOfPayment, CollectionData.customerId);
            setTableData(data.data);
            console.log(data, 'data98565');
            let invoiceData = data.data;
            console.log(invoiceData, 'temp');
            invoiceRef.current = invoiceData.invoiceData;
            let columnData = await userServicer.tableConfig(pageId1);
            setColData(columnData.data);
        }
        if (CollectionData != null || CollectionData != undefined) {
            fetchCollectionData();
        }
    }, [CollectionData]);

    console.log(invoiceRef.current, 'invoiceRef.curreent');
    console.log(tableData.data, 'tableData900');

    return (
        <>
            {tableData != null && tableData != undefined && (
                <CollectionDetails
                    collectionID={CollectionData.collectionId}
                    employeeName={CollectionData.employeeName}
                    collectionDateTime={CollectionData.date}
                    paymentMode={CollectionData.modeOfPayment}
                    collectedAmount={CollectionData.totalCollectedAmount}
                    customerName={CollectionData.customerName}
                    customerCode={CollectionData.code}
                    cashDetails={CollectionData.subModeOfPayment === PaymentMode.Cash ? tableData.paymentDetails : []}
                    cheques={(CollectionData.subModeOfPayment === PaymentMode.Cheque || CollectionData.subModeOfPayment === PaymentMode.DD) ? tableData.paymentDetails : []}
                    upiDetails={CollectionData.subModeOfPayment === PaymentMode.UPI ? tableData.paymentDetails : []}
                    neftDetails={(CollectionData.subModeOfPayment === PaymentMode.IMPS || CollectionData.subModeOfPayment === PaymentMode.NEFT || CollectionData.subModeOfPayment === PaymentMode.RTGS) ? tableData.paymentDetails : []}
                    invoices={invoiceRef.current}
                    pageIdOfTable={pageId1}
                    tableHeader={coldata}
                    open={open}
                    onClose={onClose}
                />
            )}
        </>
    );
};

export default CollectionReport;
