import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { GrDown, GrUp } from "react-icons/gr";
import { IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyncIcon from '@mui/icons-material/Sync';
import customToast from "../Toast/CustomToast";
import { useLoading } from "../../Loader/LoadingContext";
import { commanServicer } from "../../Commen_Services/common.services";
import { userServicer } from "../../Commen_Services/user.services";

interface ErrorProps {
    errorData: any;
    onPopupClose : any;
}

const ErrorDialog: React.FC<ErrorProps> = ({ errorData, onPopupClose }) => {

    const jsonError = JSON.parse(errorData.errorLog);


    const [expandedDrop, setExpandedDrop] = useState<string[]>([]);
    const [copySuccess, setCopySuccess] = useState<string>("Copy");
    const { setLoading } = useLoading();

    const toggleDrop = (key: string) => {
        setExpandedDrop((prev) =>
            prev.includes(key) ? prev.filter((id) => id !== key) : [...prev, key]
        );
    };

    const dropData = [
        { key: "Request_1", Header: "Request Payload", Value: jsonError != null ? jsonError.RequestData : '' },
        { key: "Response_1", Header: "Response", Value: jsonError != null ? jsonError.ResponseData : '' },
    ];



    const handleCopy = (jsonData: string) => {
        navigator.clipboard.writeText(jsonData)
            .then(() => {
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 1500); // Clear message after 1.5 seconds
            })
            .catch(() => setCopySuccess("Failed to copy"));
    };

    const handleReSyanc = async (data: any) => {
        setLoading(true);
        try {
            const user = await userServicer.reSynacCollectionAdminToOdoo(data.collectionId);
            debugger
            if (user.statusCode === 200) {
                if (!user.status) {
                    customToast.error(user.message);
                } else {
                    // refresh ? setRefresh(false) : setRefresh(true);
                    customToast.success(user.message ? user.message : 'Data Save Successfully');
                }
            } else {
                customToast.error(user.message ? user.message : 'Something Went Wrong');
            }
        } catch (error) {
            console.error("Error approving cash collection:", error);
            customToast.error("Something Went Wrong");
        } finally {
            setLoading(false);
            onPopupClose(false);
        }
    }

    return (
        <div className="error-dialogbox">

            <div className="error-mid">
                <div className="error-head">
                    <ErrorIcon />
                </div>
                <div>
                    {dropData.map((item) => {
                        const formattedJSON =
                            typeof item.Value === "string"
                                ? JSON.stringify(JSON.parse(item.Value), null, 2)
                                : JSON.stringify(item.Value, null, 2);

                        return (
                            <div key={item.key}>
                                {/* Collapsible Header */}
                                <div
                                    style={{
                                        padding: "10px 15px",
                                        // background: "rgb(245, 245, 245)",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #ccc",
                                        borderLeft: "1px solid #ccc",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    onClick={() => toggleDrop(item.key)}
                                >
                                    <div
                                        style={{
                                            color: "var(--body-main-dark-color)",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {item.Header}
                                    </div>
                                    <div>
                                        {expandedDrop.includes(item.key) ? <GrUp /> : <GrDown />}
                                    </div>
                                </div>

                                {/* Collapsible Content */}
                                {expandedDrop.includes(item.key) && (
                                    <div className="contentEX">

                                        <div
                                            style={{
                                                padding: "10px",
                                                border: "1px solid #ccc",
                                                borderTop: "none",
                                                background: "rgb(221, 230, 241)",
                                                paddingLeft: '10px',
                                                fontFamily: "monospace",
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            <div className="error-content">
                                                <div className="copy-button">
                                                    <IconButton onClick={() => handleCopy(formattedJSON)}>
                                                        <ContentCopyIcon />&nbsp; {copySuccess}
                                                    </IconButton>
                                                </div>
                                                <pre style={{ margin: 0 }}>
                                                    {formattedJSON}
                                                </pre>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            <button className='error-controls' onClick={(event) => { event.stopPropagation(); handleReSyanc(errorData) }} ><span className="error-btn"><SyncIcon />&nbsp;Re-Sync</span></button>
        </div>
    );
};

export default ErrorDialog;
