import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { userServicer } from "../../Commen_Services/user.services";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { SelectChangeEvent } from '@mui/material/Select';
import { stat } from "fs";
import EnhancedTable from "../../Commen_Component/EnhancedTable";
import { Link } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import customToast from "../../Commen_Component/Toast/CustomToast";
import { HeadquarterMasterService } from "../../Commen_Services/Headquarter.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

const AddHeadquarter = () => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [inputs, setInputs] = useState<any>({});
    const [dataTable, setdataTable] = useState<any[]>([0]);
    const [disable, setDisable] = useState(false);
    const [dropdownState, setdropdownState] = useState([{ OptionID: 0, OptionName: "--Select State--" }]);
    const [dropdownCity, setdropdownCity] = useState([{ OptionID: 0, OptionName: "--Select City--" }]);
    const [selectedState, setSelectedState] = useState();
    const [multiState, setmultiState] = useState<any>([]);
    const [multiDropdownCity, setmultiDropdownCity] = useState([{ OptionID: 0, OptionName: "--Select City--" }]);
    const [multiDropdownArea, setmultiDropdownArea] = useState([{ OptionID: 0, OptionName: "--Select Area--" }]);
    const [multiCity, setmultiCity] = useState<any>([]);
    const [multiArea, setmultiArea] = useState<any>([]);
    const [uniqueStateId, setuniqueStateId] = useState<any[]>([]);
    const [uniqueCityId, setuniqueCityId] = useState<any[]>([]);
    const [uniqueAreaId, setuniqueAreaId] = useState<any[]>([]);
    const [deleteforCity, setdeleteforCity] = useState(false);
    const [deleteforArea, setdeleteforArea] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [error, setError] = useState({
        hqName: '',
        state_Code: '',
        countryId: '',
        stateId: '',
        City_ID: '',
        hqcode: '',
        cityId: ''
    });
    const [options, setOptions] = useState([]);
    const [isViewStatus, setIsViewStatus] = React.useState({});
    const [coldata, setColData] = React.useState<any[]>([]);
    const { pageId } = useParams<{ pageId: any }>();
    const { action } = useParams<{ action: string }>();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [count, setCount] = useState(1);
    let pageId1 = 10;
    if (pageId != null) {
        console.log(pageId);
    }
    let deleteMessage: any;
    deleteMessage = "linked area";

    useEffect(() => {
        const fetchCity1 = async () => {
            const dataForCity = uniqueStateId.map((a:any) => a.OptionID).join(",")
            let multiCityList1 = await HeadquarterMasterService.getList("GetCityDropDown", dataForCity, pageId);
            let multiCityList = multiCityList1.data;
            const transformedData2 = multiCityList.map((item: { [x: string]: any; }) => ({
                Chk : item[Object.keys(multiCityList[0])[0]],
                OptionID: item[Object.keys(multiCityList[0])[1]],
                OptionName: item[Object.keys(multiCityList[0])[2]] == undefined ? item[Object.keys(multiCityList[0])[2]] : item[Object.keys(multiCityList[0])[2]]
            }));

            setmultiDropdownCity(transformedData2);
            if(pageId > 0){
                const cityData = transformedData2.filter((item:any) => item.Chk == 1)
                setuniqueCityId(cityData);
            }
        }

        fetchCity1()

    }, [uniqueStateId]);

    useEffect(() => {
        const fetchArea = async () => {
            const dataForArea = uniqueCityId.map((a:any) => a.OptionID).join(",")
            let multiAreaList1 = await HeadquarterMasterService.getList("GetAreaDropDown", dataForArea, pageId);
            let multiAreaList = multiAreaList1.data;
            const transformedArea = multiAreaList.map((item: { [x: string]: any; }) => ({
                Chk : item[Object.keys(multiAreaList[0])[0]],
                OptionID: item[Object.keys(multiAreaList[0])[1]],
                OptionName: item[Object.keys(multiAreaList[0])[2]] == undefined ? item[Object.keys(multiAreaList[0])[2]] : item[Object.keys(multiAreaList[0])[2]]
            }));
            setmultiDropdownArea(transformedArea);           
            if(pageId > 0){
                const AreaData = transformedArea.filter((item:any) => item.Chk == 1)
                setuniqueAreaId(AreaData);
            }
        }

        fetchArea();
        
    }, [uniqueCityId]);

    

    let coldata1: any;
    useEffect(() => {
        const fetchState = async () => {
            coldata1 = await userServicer.tableConfig(pageId1);
            setColData(coldata1.data);
            let stateList1 = await HeadquarterMasterService.getList("GetStateDropDown", null ,pageId)
            let stateList = stateList1.data;
            const transformedState = stateList.map((item: { [x: string]: any; }) => ({
                Chk : item[Object.keys(stateList[0])[0]],
                OptionID: item[Object.keys(stateList[0])[1]],
                OptionName: item[Object.keys(stateList[0])[2]] == undefined ? item[Object.keys(stateList[0])[2]] : item[Object.keys(stateList[0])[2]]

            }));
            setdropdownState(transformedState);
            console.log(transformedState,'transformedState');
            if(pageId > 0){
                const stateData = transformedState.filter((item:any) => item.Chk == 1)
                console.log(stateData,'demo');
                setuniqueStateId(stateData);
            }
        }
        fetchState()

    }, []) 

    useEffect(() => {
        linkState([]);
    }, [])

    useEffect(() => {
        if (action === 'view') {
            setDisable(true);
            getData(pageId);
        }
        else if (pageId > 0) {
            getData(pageId);
        }

    }, [state, dropdownState])


    useEffect(() => {
        const fetchCity = async () => {

            let cityList1 = await userServicer.getDropDownlist("Getcity", Number(inputs.stateId))

            let cityList = cityList1.data;
            const transformedCity = cityList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(cityList[0])[0]],
                OptionName: item[Object.keys(cityList[0])[1]] == undefined ? item[Object.keys(cityList[0])[2]] : item[Object.keys(cityList[0])[1]]
            }));

            setdropdownCity(transformedCity);
        }

        if (selectedState != null) {
            fetchCity();
        } else {
            setdropdownCity([]);
        }
    }, [selectedState])

    
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isDisabled) {
            timer = setTimeout(() => {
                setIsDisabled(false);
            }, 5000);
        }

        return () => clearTimeout(timer); 
    }, [isDisabled]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;
        setInputs((values: any) => ({ ...values, [name]: value }))
        let newError: string;

        if (name === 'hqName') {
            // if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
            //     newError = "Name should be Alphabetic & maximum of 50 characters";
            // }
            if (value.trim() === '') {
                newError = "Please enter a unique alphanumeric HQ Name.";
            }
            else if (!/^(?!.*(?:[^a-zA-Z0-9-_].*|([_-]){2,}))[a-zA-Z0-9_-]+$/.test(value)) {
                newError = "The HQ Name must be unique and can only contain alphanumeric characters, '-' and '_'.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, hqName: newError }));
        }


    }

    const handelSubmit = (event: any) => {
        event.preventDefault();
        if (!inputs.stateId) {
            setError((prevErrors) => ({ ...prevErrors, stateId: "Please Select state" }));
        }
        if (!inputs.cityId) {
            setError((prevErrors) => ({ ...prevErrors, cityId: "Please Select City" }));
        }
    }


    const handleFocus = (fieldName: any) => {
        if (fieldName == 'hqName') {
            if (!inputs[fieldName]) {
                // setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter HQ Name " + fieldName }));
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric HQ Name." }));
            }
        }
        if (fieldName == 'stateId') {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Select HQ state! " }));
            }
            if (fieldName == 'cityId') {
                if (!inputs[fieldName]) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Select HQ city! " }));
                }
            }
            if (fieldName == 'hqCode') {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
            }
        }
        if (fieldName == 'cityId') {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Select HQ city! " }));
            }
        }



    };

    const handleChangeMultipleState = (event: any) => {
        let p = event.length;
        let sData = [];
        for (var vx = 0; vx < event.length; vx++) {
            sData.push(event[vx].OptionID);
        }
        setmultiState(sData);
        setuniqueStateId(event);
        if(pageId == 0){
            setdeleteforCity(true);
        if(p == 0){
            setmultiDropdownCity([]);
            setmultiDropdownArea([]);
        }
        }
        
    };

    useEffect(() => {
        if(deleteforCity == true){
            setuniqueCityId([]);
            setuniqueAreaId([]);
            setdeleteforCity(false);
        }
    }, [deleteforCity])

    const handleChangeMultipleCity = (event: any) => {
        let p = event.length;
        let cData = [];
        for (var vx = 0; vx < event.length; vx++) {
            cData.push(event[vx].OptionID);

        }
        setmultiCity(cData);
        setuniqueCityId(event);
        if(pageId == 0){
             setdeleteforArea(true);
        if(p == 0){
            setmultiDropdownArea([]);
        }
        }
       
    };
    useEffect(() => {
        if(deleteforArea == true){
            setuniqueAreaId([])
            setdeleteforArea(false);
        } 
    }, [deleteforArea])

    const handleChangeMultipleArea = (event: any) => {
        let p = event.length;
        let aData: any[] = [];
        for (var vx = 0; vx < event.length; vx++) {
            aData.push(event[vx].OptionID);
        }
        setmultiArea(aData);
        //console.log(multiArea);

        setuniqueAreaId(event);

        let linkArea = aData.map(a => a).join(",")
        setInputs((values: any) => ({ ...values, linkArea }));
    };

    const linkState = async (id: any) => {
        //setCount(0);
        if(uniqueAreaId.length == 0){
            setdataTable([]);
            inputs.LinkedAreas = [];
        }else{
            let linkstate = await HeadquarterMasterService.getLinkTableData(id);
            let linkstate1 = linkstate.data;
            setdataTable(linkstate1)
            let LinkedAreas: any[] = [];
            LinkedAreas = linkstate1;
            setInputs((values: any) => ({ ...values, LinkedAreas }));
        }
    }

    const saveData = async (id: any) => {

        if (!inputs.hqName) {
            setError((prevErrors) => ({ ...prevErrors, hqName: 'Please enter a unique alphanumeric HQ Name.' }));
        }
        if (!inputs.cityId) {
            setError((prevErrors) => ({ ...prevErrors, cityId: 'Please Select HQ City!' }));
        }

        if (!inputs.stateId) {
            setError((prevErrors) => ({ ...prevErrors, stateId: 'Please Select HQ state!' }));
        }
        if (inputs.hqName != undefined && inputs.cityId != undefined && inputs.stateId != undefined) {
            if (error.hqName == '' && error.cityId == '' && error.stateId == '') {
                // if (count != 0) {
                //     customToast.Warning("Please Click on Link Button");
                // } else {  }
                let value = await HeadquarterMasterService.saveOrUpdateHeadQuarterData(id);

                if (value.status !== false) {
                    customToast.success(value.message);
                    navigate("/Report/view/4");
                } else {
                    customToast.error(value.message);
                    handleToastClick();
                }
            }
        }
    }

    const handleToastClick = () => {
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 5000);
    }

    const getData = async (id: any) => {
        //let value = await HeadquarterMasterService.getHQById(state.hqId);
        let value = await HeadquarterMasterService.getHQById(pageId);
        let aId = value.data.optionDetails[0].areaId;
        var temp3 = new Array();
        temp3 = aId.split(",");
        const uniqueAId = Array.from(new Set(temp3));
         setmultiArea(uniqueAId);
        linkState(multiArea);
        linkStateforgetfunction(aId);
        setInputs(value.data.header);
        let a: any = value.data.header.stateId;
        dropdownState.map((item: any) => {
            if (item.OptionID === a) {
                setSelectedState(item);
            }
        });
    }

    const linkStateforgetfunction = async (id: any) => {
        let linkstate = await HeadquarterMasterService.getLinkTableData(id);
        let linkstate1 = linkstate.data;
        setdataTable(linkstate1)
    }

    //---------------------------------------------------------------------------------------------------------------------------------------------------------------

    let val3 = { OptionName: '--Select State--' };

    if (options !== undefined && options !== null && inputs["stateId"] !== undefined && inputs["stateId"] !== null) {
        let val2 = dropdownState.filter(item => item.OptionID == inputs["stateId"]);

        let val4 = val2.length > 0 ? Object.values(val2[0]) : '';

        val3.OptionName = val4[1] != undefined ? String(val4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }

    let val5 = { OptionName1: '--Select City--' };

    if (options !== undefined && options !== null && inputs["cityId"] !== undefined && inputs["cityId"] !== null) {
        let val6 = dropdownCity.filter(item => item.OptionID == inputs["cityId"]);

        let val7 = val6.length > 0 ? Object.values(val6[0]) : '';

        val5.OptionName1 = val7[1] != undefined ? String(val7[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }


    //-------------------------------------------------------------------------------------------------------------------------------------------------------------

    const handlePagination = () => {

    }

    const handleEditButtondata = (event: any) => {
        //console.log(event);
    }

    const handleDeleteButtondata = (event: any) => {

        var a = multiArea.indexOf(event.areaId);
        let index: number;
        index = multiArea.indexOf(String(event.areaId));
        if (index == -1) {
            index = multiArea.indexOf(event.areaId);
        }

        if (index > -1) {
            multiArea.splice(index, 1);
            linkState(multiArea)
        }
        console.log(multiArea);
        console.log(uniqueAreaId);
        let tempArea: any[] = [];
        multiArea.map((item: any) => {
            uniqueAreaId.map((area: any, index) => {
                if (area.OptionID == item) {
                    tempArea.push(area);
                }
            })
        })

        console.log(tempArea);
        setuniqueAreaId(tempArea);
        if (index == -1) {
            linkState(multiArea);
        }
    }


    let valstate = { OptionName: '' };

    if (dropdownState !== undefined && dropdownState !== null && inputs["stateId"] !== undefined && inputs["stateId"] !== null) {
        let valstate2 = dropdownState.filter(item => item.OptionID === inputs["stateId"]);

        let valstate4 = valstate2.length > 0 ? Object.values(valstate2[0]) : '';

        valstate.OptionName = valstate4[1] !== undefined ? String(valstate4[2]) : '';
        

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }

    let valueCity = { OptionName: '' };

    if (dropdownCity !== undefined && dropdownCity !== null && inputs["cityId"] !== undefined && inputs["cityId"] !== null) {
        let valCity2 = dropdownCity.filter(item => item.OptionID === inputs["cityId"]);

        let valCity4 = valCity2.length > 0 ? Object.values(valCity2[0]) : '';

        valueCity.OptionName = valCity4[1] !== undefined ? String(valCity4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }

    let valmultistate = { OptionName: '' };

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {

        navigate("/Report/view/4");

    };

    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;{Number(pageId) > 0 && action !== 'view' ? <>Update </> : action == 'view' ? <>View</> : <>Add </>} Headquarter</span>
                    </div>
                    <hr className="colorgraph" />
                </div>
                <div className="headquarterDetails">
                    <h3>Headquarter Details</h3>
                </div>
                <div className="area_main">
                    <div className="area_content" >
                        <div className="grid-container-headquarter">
                            <div>
                                <TextField
                                    sx={{ marginRight: 2, width: '250px' }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="hqCode"
                                    label="HQ Code"
                                    value={inputs.hqCode}
                                    focused={!!inputs.hqCode}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.hqName ? 'red' : undefined
                                        },
                                    }}

                                />
                            </div>

                            <div>
                                <TextField

                                    sx={{ marginRight: 2 }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="hqName"
                                    value={inputs?.["hqName"]}
                                    label="HQ Name"
                                    onChange={handleChange}
                                    focused={!!inputs.hqName}
                                    onFocus={() => handleFocus("hqName")}
                                    error={!!error.hqName}
                                    helperText={error.hqName}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            width: '250px',
                                            borderColor: error.hqName ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.hqName ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.hqName ? '9px' : '12px',

                                        },
                                    }}
                                />

                            </div>

                            <div>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={dropdownState}
                                        value={valstate}
                                        readOnly={disable}
                                        onFocus={() => handleFocus("stateId")}
                                        onChange={(event, newValue: any) => {
                                            if (newValue === null) {
                                                setError((prevErrors) => ({ ...prevErrors, stateId: 'Please Select HQ state! ' }))
                                                setError((prevErrors) => ({ ...prevErrors, cityId: 'Please Select HQ city! ' }))
                                            }
                                            else {
                                                setError((prevErrors) => ({ ...prevErrors, stateId: '' }));
                                            }
                                            setInputs((values: any) => ({ ...values, stateId: newValue != null ? newValue.OptionID : '' }));
                                            setSelectedState(newValue);
                                        }}
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        sx={{ width: 250, overflowY: 'auto', overflowX: 'hidden', marginRight: 2 }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error.stateId}
                                                {...params}
                                                label="State Name"
                                                variant="standard"
                                                name="stateId"
                                                InputLabelProps={{
                                                    style: {
                                                        color: '#243C5C',
                                                        fontSize: '12px',
                                                        borderColor: 'red',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    {error.stateId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.stateId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.stateId}</FormHelperText>}
                                </FormControl>
                            </div>

                            <div>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={dropdownCity}
                                        readOnly={disable}
                                        onChange={(event, newValue: any) => {
                                            if (newValue === null) {
                                                setError((prevErrors) => ({ ...prevErrors, cityId: 'Please Select HQ city! ' }))
                                            }
                                            else {
                                                setError((prevErrors) => ({ ...prevErrors, cityId: '' }));
                                            }
                                            setInputs((values: any) => ({ ...values, cityId: newValue != null ? newValue.OptionID : '' }));
                                        }}
                                        onFocus={() => handleFocus("cityId")}
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        sx={{ width: 250, overflowY: 'auto', overflowX: 'hidden', marginRight: 2 }}
                                        value={valueCity}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error.cityId}
                                                {...params}
                                                label="City Name"
                                                variant="standard"
                                                name="cityId"
                                                value={inputs?.cityId}
                                                InputLabelProps={{
                                                    style: {
                                                        color: '#243C5C',
                                                        fontSize: '12px',
                                                        borderColor: 'red',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    {error.cityId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.cityId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.cityId}</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="headquarterDetails">
                    <h3>Link Area</h3>
                </div>


                <div className="area_main">
                    <div className="area_content" >
                        <div className="grid-container-headquarter">

                            <div>
                                <FormControl >
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={dropdownState}
                                        value={uniqueStateId}
                                        readOnly={disable}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        onChange={(event, newvalue: any) => handleChangeMultipleState(newvalue)}
                                        sx={{ width: 100, marginRight: 5, color: 'black' }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.OptionName}
                                            </li>
                                        )}
                                        style={{ width: 200 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="State" placeholder="Select"
                                            />
                                        )}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl >
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={multiDropdownCity}
                                        value={uniqueCityId}
                                        readOnly={disable}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        onChange={(event, newvalue: any) => handleChangeMultipleCity(newvalue)}
                                        sx={{ width: 250, marginRight: 5, color: 'black' }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.OptionName}
                                            </li>
                                        )}
                                        style={{ width: 200 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="City" placeholder="Select" />
                                        )}
                                    />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl >
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={multiDropdownArea}
                                        value={uniqueAreaId}
                                        disableCloseOnSelect
                                        readOnly={disable}
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        onChange={(event, newvalue: any) => handleChangeMultipleArea(newvalue)}
                                        sx={{ width: 250, marginRight: 5, color: 'black' }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.OptionName}
                                            </li>
                                        )}
                                        style={{ width: 200 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Area" placeholder="Select" />
                                        )}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <button className='btn123 upbtn' disabled={isDisabled} onClick={(event) => { linkState(multiArea) }} style={{ padding: 20, height: '30px', marginLeft: '1%', backgroundColor: '#243C5C', color: 'white', marginTop: '-0.4555px' }}><Link />&nbsp;Link</button>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br />
                {
                    //state !== null ? state.isViewData ?
                    (action === "view" && pageId > 0) ?
                        <div style={{ maxHeight: 350, overflow: 'hidden' }} className="Headtable">
                            {

                                (<EnhancedTable pageID={String(pageId1)} tableHeight={'250px'} buttonSum={[8]} columnData={coldata} tableDatafromParent={dataTable} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} deleteEvent={handleDeleteButtondata} deleteConformationMsg={deleteMessage} historyQuery={null} historyColumnParameter={null} refreshGrid={null} />)
                            }

                        </div>
                        : <div style={{ maxHeight: 350, overflow: 'hidden' }}>
                            {

                                (<EnhancedTable pageID={String(pageId1)} tableHeight={'250px'} buttonSum={[8]} columnData={coldata} tableDatafromParent={dataTable} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} deleteEvent={handleDeleteButtondata} deleteConformationMsg={deleteMessage} historyQuery={null} historyColumnParameter={null} refreshGrid={null} />)
                            }

                        </div>
                    // : <div style={{ maxHeight: 350, overflow: 'hidden' }} className="Headtable">
                    //     {

                    //         (<EnhancedTable pageID={String(pageId1)} tableHeight={'250px'} buttonSum={[8]} columnData={coldata} tableDatafromParent={dataTable} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} deleteEvent={handleDeleteButtondata} deleteConformationMsg = {deleteMessage} />)
                    //     }

                    // </div>

                }
                {/* <div className="hqButton" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '100px' }}> */}
                <div className="hqButton">

                    {
                        //state !== null ? state.isEditData ? <>
                        (action !== "view" && pageId > 0) ? <>
                            <button className='btnHQ' disabled={isDisabled} onClick={(event) => { saveData(inputs) }} style={{ padding: 20, height: '30px', backgroundColor: '#243C5C', color: 'white' }}>&nbsp;Update</button>&nbsp;&nbsp;
                            <button className='btnHQ' onClick={(event) => { setShowConfirmation(true) }} style={{ padding: 20, height: '30px' }}>&nbsp;Cancel</button>
                        </> : <></>
                        //: <></>
                    }
                    {
                        //state !== null ? state.isViewData ? <>
                        (action === "view" && pageId > 0) ? <>
                            <button className='btnHQ' onClick={(event) => { handleConfirmExit() }} style={{ padding: 20, height: '30px' }}>&nbsp;Back</button>
                        </> : <></>
                        //: <></>

                    }
                    {
                        //state === null && (
                        (pageId == 0) ?
                            <>
                                <button className='btnHQ' disabled={isDisabled} onClick={(event) => { saveData(inputs) }} style={{ padding: 20, height: '30px', backgroundColor: '#243C5C', color: 'white' }}>&nbsp;Save</button>&nbsp;&nbsp;
                                <button className='btnHQ' onClick={(event) => { setShowConfirmation(true) }} style={{ padding: 20, height: '30px' }}>&nbsp;Cancel</button>

                            </> : <></>

                    }

                    {showConfirmation && (
                        <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                            <div className="confirmation-modal">
                                <p>Are you sure you want to cancel? <br />(Any unsaved changes will be lost.)</p>
                                <div className="canel_btns">
                                    <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                    <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                                </div>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>

        </>
    );
}

export default AddHeadquarter;
