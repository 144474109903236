import { commanServicer } from "../Commen_Services/common.services";

export const CashApprovalList = {
    SaveorUpdate,
    getCashCollectionDetails,
    approveCashCollection

   
}

async function SaveorUpdate(MasterData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(MasterData), true, 'CashApproval/saveupdate','POST');
}

async function getCashCollectionDetails(CascollectionData : any) {
    return await commanServicer.fetchRequest(JSON.stringify(CascollectionData),true,'CashApproval/getcashcollectiondetails','POST');
}

async function approveCashCollection(CollectionData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(CollectionData), true, 'CashApproval/approvecollection','POST');
}

