import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";

import { userServicer } from "../../Commen_Services/user.services";
import { Autocomplete, Box } from "@mui/material";
import { InputType } from "../../../_enums/enum.services";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OptionMenu from "./MultiSelectSelection";
import customToast from "../Toast/CustomToast";



interface propsHead {
    pageID: string;
    columndata: any[];
    filterformData: ({ key: string; value: any }[]);
    filterFormDataforChips: ({ key: string; value: any }[]);
    //sendDataToParent: ({ key: string; value: any }[]) => void;
    sendDataToParent: (event: { key: string; value: any }[]) => void;
    openClose: any;
    ReportConfigData: RowData[];
    //sendDataToParentforChips: ({ key: string; value: any }[]);
    sendDataToParentforChips: (event: { key: string; value: any }[]) => void;


}

interface RowData {
    [key: string]: any;
}


interface Opt {
    OptionID: string;
    OptionName: string;
}


const Popfilterform: React.FC<propsHead> = ({ pageID, columndata, filterformData, filterFormDataforChips, sendDataToParent, sendDataToParentforChips, openClose, ReportConfigData }) => {


    const [inputs, setInputs] = useState<any>({});
    const [inputsforchips, setInputsforChips] = useState<any>({});
    const [options, setOptions] = useState([]);
    const [dropDownField, setDropDownField] = useState([]);
    const [dp, setDP] = useState<any>([]);
    const [op, setOp] = useState<any>({});
    const [selectedField, setSelectedField] = useState({});
    const [startDate, setStartDate] = useState<any>("");
    const [errors, setErrors] = useState<any>({});
    const [dateInputs, setDateInputs] = useState<Record<string, string>>({});
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [multiSelectValue, setMultiSelectVlaue] = useState<Record<string, any>>({});
    
    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values: any) => ({ ...values, [name]: value }))
    };

    const handleChangeforChips = (data: { key: string; value: any }) => {
        setInputsforChips((values: any) => ({ ...values, [data.key]: data.value }))
    }

    const closePopup = () => {
        openClose(false)
    }

    const submitFilterData = (event: any) => {
    
        event.preventDefault();
        let isReadyToSend = true;
        Object.keys(inputs).forEach((data :any) => {
            if(inputs[data] === "")
            {
                delete inputs[data];
            }
        });
        const inputArray = Object.keys(inputs);
        if(inputArray.length > 0)
        {
            const data = columndata.filter(col => 
                                                inputArray.some((item: any) => 
                                                    item === `${col.tableFieldName}_from` || 
                                                    item === `${col.tableFieldName}_to`
                                            ));
            data?.map((item) => {
                if(!inputs[item.tableFieldName+'_from'] || errors[item.tableFieldName+'_from'])
                {
                    setErrors((prevErrors: any) => ({ ...prevErrors, [item.tableFieldName+"_from"] : 'Please enter a valid date' }));
                    isReadyToSend = false;
                }
                if(!inputs[item.tableFieldName+'_to'] || errors[item.tableFieldName+'_to'])
                {
                    setErrors((prevErrors: any) => ({ ...prevErrors, [item.tableFieldName+"_to"] : 'Please enter a valid date' }));
                    isReadyToSend = false;
                }      
            })

            if(isReadyToSend)
            {
                const chipsdata: any[] = []
                columndata.map((item) => {
                    if(item.fieldType == InputType.Date || item.fieldType == InputType.DateTime)
                    {
                        if(inputsforchips[item.headerName + ' from'])
                        {
                                chipsdata.push({key: item.headerName + ' from', value: inputsforchips[item.headerName + ' from']});
                        } 
                        if(inputsforchips[item.headerName + ' To '])
                        {
                            chipsdata.push({key: item.headerName + ' To ' , value: inputsforchips[item.headerName + ' To ']});
                        }     
                    }
                    else
                    {
                        if(inputsforchips[item.headerName])
                        {
                            chipsdata.push({key: item.headerName, value: inputsforchips[item.headerName]});
                        }
                    }
                })

                const transformedData = Object.keys(inputs).map(key => ({
                    key: key,
                    value: inputs[key]
                }))                                                                    //  Jignesh : .filter(item => item.value !== ""); removed from transformedData to  handle two time filter data 
                const transformedData2 = Object.keys(inputsforchips).map(key => ({
                    key: key,
                    value: inputsforchips[key]
                }))
                sendDataToParent(transformedData);
                sendDataToParentforChips(chipsdata);
                closePopup();
            }
        }
        else
        {
            customToast.Warning("Please select at least one filter criterion before applying.");
        }
    }

    const transformFilterData = (formData: ({ key: string; value: any }[])) => {
        return formData.reduce((acc, curr) => {
            acc[curr.key] = curr.value;
            return acc;
        }, {} as Record<string, string>);
    };

    const transformDateData = (formData: ({ key: string; value: any }[])) => {
        return formData.reduce((acc, curr) => {
            (typeof curr.value) == "string"  &&  (acc[curr.key] = curr.value);
            return acc;
        }, {} as Record<string, string>);
    };


    useEffect(() => {
        const dropdownField1 = columndata.find(field => field.autoSearchKey !== null && field.autoSearchKey !== undefined && field.autoSearchKey !== "");
        const dropdownField2: any[] = columndata.filter(item => item.autoSearchKey != null)
        setDP(dropdownField2);
        if (dropdownField1) {
            setDropDownField((prevValues): any => [...prevValues, dropdownField1.autoSearchKey]);
        }
    }, [columndata])

    useEffect(() => {
        
        const transformedData = transformFilterData(filterFormDataforChips);
        setInputsforChips(transformedData);

    }, [filterFormDataforChips]);



    useEffect(() => {

        const transformedData = transformFilterData(filterformData);
        // console.log(transformedData,'filterFormData');
        // console.log(filterformData,'filterFormData');
        // console.log(dateInputs,'filterFormData');
        const transformedDateData = transformDateData(filterformData);
        setDateInputs(transformedDateData);

        setInputs(transformedData);
        const fetcOption = async () => {
            const promises = dp.map(async (item: any) => {
                const data = await userServicer.getDropDownlist(item.autoSearchKey, null);
                const h = data.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));
                return { [item.autoSearchKey]: transformedData };
            });

            const results = await Promise.all(promises);
            const newState = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            setOp((prevvalue: any) => ({ ...prevvalue, ...newState }));
            // const data = await userServicer.getDropDownlist(dropDownField[0]);
            // const h = data.data;
            // const transformedData = h.map((item: { [x: string]: any; }) => ({
            //     OptionID: item[Object.keys(h[0])[0]],
            //     OptionName: item[Object.keys(h[0])[1]] == undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
            // }));
            // setOptions(transformedData);
        }

        if (dp !== null || dp !== undefined) {
            fetcOption();
        }
    }, [filterformData, dp])

    useEffect(() => {
        let x1: any[] = Object.keys(op);
        x1.map(item => {
            console.log(Object.values(op[item]));
        });
    }, [op]);

    const selectedValues: Record<string, any> = React.useMemo(() => {
        let result: Record<string, any> = {}; // Initialize an empty object
    
        columndata.forEach((field) => {
            const autosearchKey = field.autoSearchKey as string;
            let inputValue = inputs[field.tableFieldName]; // Get input value
    
            if (autosearchKey && inputValue && field.fieldType === InputType.MultiSelect) {
                if (typeof inputValue === "number") {
                    inputValue = inputValue.toString();
                }
                const filterIds = inputValue.split(",").map((val: any) => {
                    const trimmedValue = val.trim();
                    return !isNaN(Number(trimmedValue)) ? Number(trimmedValue) : trimmedValue;
                });

                const val2 = op[autosearchKey]?.filter((item: any) => filterIds.includes(item.OptionID) || filterIds.includes(item.OptionName));
    
                result[field.tableFieldName] = val2 || []; 
            }
        });  
        return result;
    }, [inputs, columndata, op]); 
    


    const handleChangeMUI = (event: any) => {

        const name = event.target.name;
        const value = event.target.value;
        // console.log(name + ":" + value);
        setInputs((values: any) => ({ ...values, [name]: value }))
        setInputsforChips((values: any) => ({ ...values, [name]: value }))

        //setSelectedField(values => ({ ...values, [name]: value }));
    }

    
    const handleChangeforFromDate = ((event: any,fieldName : any) => {
        const value = event.target.value;
        setDateInputs((prev) => ({
                ...prev,
                [fieldName+"_from"]: value,
            })); 
        
        const startDateCheck = new Date(value);
        const endDateCheck = new Date(dateInputs[fieldName+"_to"]);
        const ch = startDateCheck > endDateCheck;         
        if (startDateCheck > endDateCheck) {
            setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_from"]: 'Please enter a valid date' }));
            setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_to"] : 'Please enter a valid date' }));
        }
        else {
            setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_from"]: '' }));
            setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_to"]: '' }));
            setInputs((values: any) => ({ ...values, [fieldName+"_from"]: value }));
            //setInputs((values: any) => ({ ...values, [fieldName+"_to"]:  dateInputs[fieldName+"_to"] ? (value != "" ? dateInputs[fieldName+"_to"] : "") : (value != "" ? new Date().toISOString().split("T")[0] : "")}));

        }

        if(!validateDate(startDateCheck))
        {
            setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_from"]: 'Please enter a valid date' }));
    
        }
    });

    const handleChangeforToDate = ((event: any,fieldName : any) => {
        
        const value = event.target.value;
        setDateInputs((prev) => ({
                ...prev,
                [fieldName+"_to"]: value,
            }));
        const startDateCheck = new Date(dateInputs[fieldName+"_from"]);
        // console.log(startDateCheck,'startDate');
        const endDateCheck = new Date(value);
        const ch = startDateCheck > endDateCheck;         
        // if(dateInputs[fieldName+"_from"])
        // {
            if (startDateCheck > endDateCheck) {
                setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_to"] : 'Please enter a valid date' }));
            }
            else {
                setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_from"]: '' }));
                setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_to"]: '' }));
                setInputs((values: any) => ({ ...values, [fieldName+"_to"]: value}));

                //setInputs((values: any) => ({ ...values, [fieldName+"_to"]: value != "" && dateInputs[fieldName+"_from"] ? value : new Date().toISOString().split("T")[0]}));
            }
    
            if(!validateDate(endDateCheck))
            {
                setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName+"_to"]: 'Please enter a valid date' }));
            }
        // }   
    });


    const validateDate = (dateString: any) => {
        
        // if (!dateString) {
        //     return false;
        // }
        const inputDate = new Date(dateString);
        const today = new Date();
        if (inputDate > today) {
            return false;
        }
        return true;
    };

    const handleChangeMultipleSELECT = (event1: any,event: any, tableFieldName: any, tableHeaderName: any) => {
        let p = event.length;
        // console.log(event1);
        let inputData: any[] = [];
        let chipsData: any[] = [];
        for (var vx = 0; vx < event.length; vx++) {
            inputData.push(event[vx].OptionID);
            chipsData.push(event[vx].OptionName);

        }
        //setmultiArea(aData);
        //console.log(multiArea);

        //setuniqueAreaId(event);

        let inputIds = inputData.map(a => a).join(",")
        let chipsIds = chipsData.map(a => a).join(",")
        //console.log(linkArea);
        setInputs((values: any) => ({ ...values, [tableFieldName]: inputIds }));
        setInputsforChips((values: any) => ({ ...values, [tableHeaderName]: chipsIds }));

    };
    

    



    const renderForm1 = () => {


        return (
            <>
                {columndata.map((field, index) => {
                    let c = field.column_name;
                    const autosearh = field.autoSearchKey as string;
                    //console.log(autosearh);

                    if (field.autoSearchKey === null || field.autoSearchKey === undefined || field.autoSearchKey === "") {


                        if (field.fieldType === InputType.Text) {
                            return (
                                <div key={`${field.tableFieldName}-${index}`} className="grid-item">

                                    {/* <TextField id="standard-basic" name={field.tableFieldName} value={field.tableFieldName == filterformData[0].key ? filterformData[0].value : ""} label={field.headerName} type="search" /> */}
                                    <TextField id="standard-basic" key={`${field.tableFieldName}-${index}-${field.headerName}`} variant="standard" name={field.tableFieldName} value={inputs[field.tableFieldName]} label={field.headerName}
                                        //onChange={handleChange}
                                        onChange={(event) => { handleChangeforChips({ key: field.headerName, value: event.target.value }); handleChange(event); }}

                                        InputProps={{
                                            style: {
                                                color: '#243C5C',
                                                fontSize: '14px',
                                                width: '200px',
                                                borderColor: 'red'
                                            }

                                        }}

                                        InputLabelProps={{
                                            style: {
                                                color: '#243C5C',
                                                fontSize: '12px',
                                                borderColor: 'red',

                                            },
                                        }}

                                    />
                                </div>

                            );

                        }
                        else if (field.fieldType === InputType.Number) {
                            return ( //Update NumberField (Jignesh)
                                <TextField
                                    id="filled-number"
                                    label={field.headerName}
                                    type="number"
                                    key={`${field.tableFieldName}-${index}-number`}
                                    value={inputs[field.tableFieldName]}
                                    name={field.tableFieldName}
                                    onChange={(event) => { handleChangeforChips({ key: field.headerName, value: event.target.value }); handleChange(event); }}
                                    InputProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '14px',
                                            width: '200px',
                                            borderColor: 'red'
                                        }

                                    }}

                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: 'red',

                                        },
                                    }}
                                    variant="standard"
                                />

                            );
                        }
                        else if (field.fieldType === InputType.Dropdown) {
                            return (
                                <div key={`${field.tableFieldName}-${index}`} className="col1">
                                    <div className="inputGroup">
                                        <select className="inputtxt" name={field.columnData} onChange={handleChange} >
                                            <option>----- select status ------</option>
                                            <option >Un Approved</option>
                                            <option>Approved</option>
                                            <option>Rejected</option>
                                            <option>dispatched</option>
                                            <option>onhold</option>
                                            {/* {options[dropdownField.column_name].map((option, i) => (
                                        <option value={option.OptionID} key={i}>{option.OptionName}</option>
                                    ))} */}
                                        </select>
                                    </div>
                                </div>
                            );

                        }
                        else if (field.fieldType === InputType.Date || field.fieldType === InputType.DateTime) {
                            return (
                                <>
                                <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name={field.tableFieldName+"_from"}
                                type="date"
                                value={dateInputs[field.tableFieldName+"_from"]}
                                label={field.headerName + " From "}
                                onChange={(event) => { handleChangeforChips({ key: field.headerName + " from", value: event.target.value });handleChangeforFromDate(event,field.tableFieldName);}}
                               // onChange={(event) => { handleChangeforChips({ key: field.headerName + " from", value: event.target.value });handleChangeforChips({ key: field.headerName + " To ", value: dateInputs[field.tableFieldName+"_to"] ? (event.target.value != "" ? dateInputs[field.tableFieldName+"_to"] : "" ) : event.target.value != "" ? " " : ""}); handleChangeforFromDate(event,field.tableFieldName);}}
                                error={!!errors[field.tableFieldName+"_from"]}
                                helperText={errors[field.tableFieldName+"_from"]}
                                InputProps={{
                                    inputProps: {
                                        className: dateInputs[field.tableFieldName+"_from"] ? '' : 'custom-date-input', 
                                        max: "9999-12-31",
                                      },
                                    style: {
                                        color: dateInputs[field.tableFieldName+"_from"] ? '#243C5C' : 'black',
                                        fontSize: '14px',
                                        width: '200px',
                                    
                                    },
                                    onInput: (event: any) => {
    
                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value }))
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: errors[field.tableFieldName+"_from"] ? 'red' : undefined,    
                                    },
                                    
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: errors[field.tableFieldName+"_from"] ? '9px' : '12px',
                                        maxWidth: '210px'
    
                                    },
                                }}
                                
                                />
                                <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name={field.tableFieldName+"_to"}
                                type="date"
                                //value={startDate}
                                value={((typeof dateInputs[field.tableFieldName+"_to"]) == "string" ) ? dateInputs[field.tableFieldName+"_to"] : startDate}
                                label={field.headerName + " To "}
                                onChange={(event) => { handleChangeforChips({ key: field.headerName + " To ", value: event.target.value}); handleChangeforToDate(event, field.tableFieldName);}}
                                //onChange={(event) => { dateInputs[field.tableFieldName+"_from"] && handleChangeforChips({ key: field.headerName + " To ", value: event.target.value != "" ? event.target.value : " " }); handleChangeforToDate(event, field.tableFieldName);}}
                                error={!!errors[field.tableFieldName+"_to"]}
                                helperText={errors[field.tableFieldName+"_to"]}
                                InputProps={{
                                    inputProps: {
                                        className: dateInputs[field.tableFieldName+"_to"] ? '' : 'custom-date-input',
                                        max: "2030-12-31", 
                                      }, 
                                    style: {
                                        color: dateInputs[field.tableFieldName+"_to"] ? '#243C5C' : 'black',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: errors[field.tableFieldName+"_to"] ? 'red' : undefined,
                                    
                                    },
                                    onInput: (event: any) => {
    
                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value }))
                                            // setDateInputs((prev) => ({
                                            //     ...prev,
                                            //     [field.tableFieldName+"_to"]: event.target.value,
                                            // }));
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: errors[field.tableFieldName+"_to"] ? 'red' : undefined,
                                        
                                        
                                    },
                                    
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: errors[field.tableFieldName+"_to"] ? '9px' : '12px',
                                        maxWidth: '210px'
    
                                    },
                                }}
                            />
                        </>);
                        }
                        

                    }
                    else {

                        if(field.fieldType === InputType.Dropdown)
                        {

                            let val3 = { OptionName: '' };

                            if (op !== undefined && op !== null && inputs[field.tableFieldName] !== undefined && inputs[field.tableFieldName] !== null) {
                                // console.log(op);
    
                                let val2 = op[autosearh]?.filter((item: any) => item.OptionID == inputs[field.tableFieldName]);
                                //let val2 = op["DesignationCode"].filter(item => item.OptionID == inputs[field.tableFieldName]);
                                // console.log(val2);
                                let val4 = val2?.length > 0 ? Object.values(val2[0]) : '';
                                // console.log(val4);
                                val3.OptionName = val4[1] !== undefined ? String(val4[1]) : '';
                            } else {
                                console.log('options or inputs[field.tableFieldName] is undefined or null');
                            }
    
    
    
    
    
                            return (
                                <>
    
    
                                    {
                                        Object.keys(op).map((item, indexauto) => {
    
    
                                            if (item === autosearh) {
                                                let arr = []
                                                arr = Object.values(op[item]);
    

    
    
    
                                                return (
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={arr}
                                                        value={val3}
                                                        key={`${field.tableFieldName}-${indexauto}-${item}-${index}`}
                                                        onChange={(event, newValue: any) => {
    
    
                                                            setInputs((values: any) => ({ ...values, [field.tableFieldName]: newValue != null ? newValue.OptionID : '' }));
                                                            setInputsforChips((values: any) => ({ ...values, [field.headerName]: newValue != null ? newValue.OptionName : '' }));
    
                                                        }}
                                                        getOptionLabel={(option: any) => option.OptionName}
                                                        //getOptionKey={(option: any) => option.OptionID}
                                                        isOptionEqualToValue={(option, value) => option.OptionName === value.OptionName}
                                                        sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden' }}
                                                        renderOption={(props, option, index) => {
    
                                                            return (
                                                                <Box component="li" {...props} key={`${option.OptionID}-${index.index}-${item}`}>
                                                                    {option.OptionName}
                                                                    {/* {index.index} */}
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                key={`${field.tableFieldName}-${index}-${item}-Search`}
                                                                {...params}
                                                                label={field.headerName}
                                                                variant="standard"
                                                                name={field.tableFieldName}
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: '#243C5C',
                                                                        fontSize: '12px',
                                                                        borderColor: 'red',
    
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )
                                            }
                                        })
    
                                    }
    
    
    
    
                                </>
                            );
                        }
                        else if(field.fieldType === InputType.MultiSelect)
                            {
                                let val3:{OptionID:any, OptionName: any}[] = [];

                                return (
                                    <>
                                        {
                                        Object.keys(op).map((item, indexauto) => {
    
    
                                            if (item === autosearh) {
                                                let arr :{OptionID:any, OptionName: any}[] = []
                                                arr = Object.values(op[item]);
                                                return (
                                                    <>
                                                        <OptionMenu 
                                                            options={arr} 
                                                            selectedValues={selectedValues[field.tableFieldName] ? selectedValues[field.tableFieldName] : val3} 
                                                            label={field.headerName}
                                                            tableFieldName={field.tableFieldName}
                                                            headerName={field.headerName}
                                                            handleChangeMultipleSELECT={handleChangeMultipleSELECT}
                                                        />
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                    </>
                                );
                            }
                    }

                })}
            </>
        );
    }

    const resetForm = (event: any) => {
        event.stopPropagation();
        
        for (const key in inputs) {
            setInputs((values: any) => ({ ...values, [key]: '' }));
            
        }
        setDateInputs({});

        for (const key in inputsforchips) {
            setInputsforChips((values: any) => ({ ...values, [key]: '' }));
        }

    }



    return (
        <>
            <div className="pop-main">
                <div className="pop-head"><span className="pop-form">&nbsp;<FaFilter />&nbsp;&nbsp;{(ReportConfigData as any).reportName} Filter</span></div>

                <div className="pop-form">
                    {/* <form onSubmit={submitFilterData}> */}
                    <div className="grid-container">
                        {renderForm1()}
                    </div>

                    <div className='btns'>
                        <div>
                            <button className='btn1' onClick={resetForm} ><span><GrPowerReset /></span>Reset</button>

                        </div>
                        <div>



                            <button className='btn1' onClick={submitFilterData}><span><FaFilter /></span> Filter</button>&nbsp;
                            <button className='btn1' onClick={(event) => { event.stopPropagation(); closePopup() }} ><span><IoClose /></span>Close</button>
                        </div>

                    </div>

                    {/* </form> */}

                </div>
            </div>
        </>
    );
}

export default Popfilterform;