import React, { useEffect } from "react";
import { Autocomplete, TextField, Checkbox, Box } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filterOptions = createFilterOptions<Option>({
  matchFrom: "any",
  limit: 500,
});

interface Option {
  OptionID: string | number;
  OptionName: string;
}

interface OptionMenuProps {
  options: Option[];
  selectedValues: Option[];
  label: string;
  tableFieldName: string;
  headerName: string;
  handleChangeMultipleSELECT: (
    event: React.SyntheticEvent,
    newValues: Option[],
    tableFieldName: string,
    headerName: string
  ) => void;
  width?: number;
}

const OptionMenu: React.FC<OptionMenuProps> = ({
  options,
  selectedValues,
  label,
  tableFieldName,
  headerName,
  handleChangeMultipleSELECT,
  width = 200,
}) => {
  const [allSelected, setAllSelected] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  // Combine "Select All" with the available options.
  const allOptions: Option[] = React.useMemo(
    () => [{ OptionID: 0, OptionName: "Select All" }, ...options],
    [options]
  );

  React.useEffect(() => {
    setAllSelected(selectedValues.length === options.length);
  }, [selectedValues, options]);

  const handleChange = (event: React.SyntheticEvent, newValues: Option[]) => {
    event.stopPropagation();
    event.preventDefault();
    if (newValues.some((item) => item.OptionID === 0)) {
      newValues = allSelected ? [] : options;
      setAllSelected(!allSelected);
    }
    handleChangeMultipleSELECT(event, newValues, tableFieldName, headerName);
  };

  // const handleClick = () => {
  //   setOpen(true);
  // };


  return (
     <div className={`report-filter ${open ? "dropdown" : ""} `}>
    
     {/* </div><div className={selectedValues?.length > 0 ? (open ? "report-filter dropdown" : "report-filter") : open ? "report-filter dropdown" : "report-filter"}  >  */}
      <Autocomplete<Option, true, false, false>
        multiple
        options={allOptions}
        limitTags={1}
        filterOptions={filterOptions}
        open={open}
        onFocus={() => setOpen(true)}
        onClick={() => setOpen(true)}
        onSelect={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disablePortal
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option.OptionName}
        value={selectedValues}
        isOptionEqualToValue={(option, value) =>
          option.OptionID === value.OptionID
        }
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderOption={(props, option, { selected }) => (
          <Box component="li" {...props} key={option.OptionID}>
            <Checkbox checked={option.OptionID === 0 ? allSelected : selected} />
            {option.OptionName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            InputLabelProps={{
              style: { color: "#243C5C", fontSize: "12px" },
            }}
            // style={{ overflowY: "auto", overflowX: "hidden" }}
            // onScroll={() => setOpen(false)}
            onClick={() => setOpen(true)}
          />
        )}
      />
    </div>
  );
};

export default OptionMenu;


export { };
