import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useParams, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login/Login';
import { ToastContainer } from 'react-toastify';
import MasterRouting2 from './App/Masters/MasterRouting2';
import CposRoutingmodule from './App/Module/CposRoutingmodule';
import Popup from 'reactjs-popup';
import Logoutpopup from './pages/Login/Logoutpopup';
import PageNotFound from './pages/PageNotFound';
import BadRequest from './pages/BadRequest';
import { LinearProgress } from '@mui/material';
import { userServicer } from './App/Commen_Services/user.services';
import { useLoading } from './App/Loader/LoadingContext';


function App() {

  const [token, setToken] = useState(localStorage.getItem('user'));
  const [showlogoutPopup, setlogoutShowPopup] = useState(false);
  const { loading } = useLoading();

  // useEffect(() => {

  //   const storedToken = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : '');
  //   if (storedToken) {
  //     setToken(storedToken.access_token);
  //   }
  // }, []);

  const handleLogin = (newToken: any) => {

    // console.log(newToken);

    localStorage.setItem('user', JSON.stringify(newToken.data));
    setToken(newToken.data.access_token);
  };

  const handleLogout = async () => {
    try {
      const result = await userServicer.logOut();
      localStorage.removeItem('user');
      setToken(null);
      window.location.href = "/";    
    }
    catch {
      console.log("error While fetchiung Data");

    }
    // debugger
    // if (result) {
    //   localStorage.removeItem('user');
    //   setToken(null);
    // }
  };

  useEffect(() => {
    const disableEvents = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const preventEscape = (event: KeyboardEvent) => {
      if (loading && event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (loading) {
      document.addEventListener("click", disableEvents, true);
      document.addEventListener("keydown", disableEvents, true);
      document.addEventListener("wheel", disableEvents, { passive: false });
      document.addEventListener('mousedown', disableEvents, true);
      document.addEventListener("keydown", preventEscape, true);
    } else {
      document.removeEventListener("click", disableEvents, true);
      document.removeEventListener("keydown", disableEvents, true);
      document.removeEventListener("wheel", disableEvents); 
      document.removeEventListener("keydown", preventEscape, true);
    }

    return () => {
      document.removeEventListener("click", disableEvents, true);
      document.removeEventListener("keydown", disableEvents, true);
      document.removeEventListener("wheel", disableEvents);
      document.removeEventListener("keydown", preventEscape, true);
      document.removeEventListener('mousedown', disableEvents, true);
    };
  }, [loading]);




  return (

    <>
      <div style={{position: 'relative'}}>
        {loading && (
          <div className="loader-overlay">
            <div className="loading">
              <div className="logo">
                <img src="Theme_23/images/logo-ethics.png" alt="Logo" />
              </div>
              <div className="d1"></div>
              <div className="d2"></div>
            </div>
          </div>
        )}
        <div className={loading ? "loading-disabled" : ""}>
          <Router>
            <Routes>
              <Route path="/" element={token ? <CposRoutingmodule onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} >
                <Route path='/Master/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >
                  <Route path=":pageId" element={<MasterRouting2 />} />
                  <Route path=':action' element={<MasterRouting2 />} />
                  <Route path=':action/:pageId' element={<MasterRouting2 />} />
                </Route>
                <Route path="/Report" element={<CposRoutingmodule onLogout={handleLogout} />} >
                  <Route path=":componentName/:pageId" element={<CposRoutingmodule onLogout={handleLogout} />} />
                </Route>
                <Route path='/User/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >
                </Route>
                <Route path='/Report/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >
                </Route>

              </Route>
              <Route path='/Pagenotfound' element={<BadRequest />} />

            </Routes>
            <ToastContainer position="top-center" style={{ zIndex: 1000000000000 }} />
            {/* <Popup contentStyle={{ height: 250, overflowy: "auto", width: 450, padding: 0 }} open={showlogoutPopup} onClose={() => setlogoutShowPopup(false)} children={undefined}>
        <Logoutpopup />
      </Popup> */}

          </Router>
        </div>
      </div>

    </>
  );
}

export default App;
