import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EnhancedSearchbar from "../Commen_Component/EnhancedSearchbar";
import { FaUser } from "react-icons/fa";
import EnhancedTable from "./EnhancedTable";
import { userServicer } from "../Commen_Services/user.services";
import { AccessLevel, PageEnums } from "../../_enums/enum.services";
import AddState from "../Masters/StateMaster/AddState";
import AddCity from "../Masters/CityMaster/AddCity";
import AddDesignation from "../Masters/Designation/AddDesignation";
import AddArea from "../Masters/AreaMaster/AddArea";
import { Hidden } from "@mui/material";
import { RoleRightMasterService } from "../Commen_Services/RoleRightMaster.service";


interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
    fieldType: Number;
}

interface RowData {
    [key: string]: any;
}

interface ReportConfigData {
    viewButtonsSum: number;
}

const EnhancedPage: React.FC<{ pageId: any }> = ({ pageId }) => {

    const { paramPageId } = useParams();
    const { componentName, param1 } = useParams();
    let updatedPageID = pageId || paramPageId;
    const [columnData, setColumnData] = useState<ColumnData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [pageCount, setPageCount] = useState(0);
    const [tableData, setTableData] = useState<RowData[]>([]);
    const valueRef = useRef<RowData[]>([]);
    const [csvData, setCSVData] = useState<RowData[]>([]);
    const [reportConfigData, setreportConfigData] = useState<any>([]);
    const [firtpageNum, setfirstPageNum] = useState(1);
    const [secondpagenum, setSecondPagenum] = useState(perPage);
    const navigate = useNavigate();
    const [viewButtonSum, setButtonSum] = useState<number[]>([]);
    const [filterFormData, setFilterFormData] = useState<{ key: string; value: any }[]>([]);
    const [defaultSeacrh1, setdefaultSeacrh] = useState<{ key: string; value: any }[]>([]);
    const [fullWidth, setFullWidth] = useState(false);
    const handleFilterData = (event: { key: string; value: any }[]) => {


        setFilterFormData(event);

    };
    const [tableColumns, settableColumns] = React.useState<RowData[]>(columnData);
    const [exportColumns, setExportColumns] = React.useState<RowData[]>(columnData);
    const [editRowData, setEditRowData] = React.useState<any>();
    const [refreshData, setRefreshData] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({});
    const [isViewStatus, setIsViewStatus] = React.useState({});
    const [isDefaultSearch, setIsDefaultSearch] = React.useState(false);

    const handleViewStatus = (item: any) => {
        let data = { isViewStatus: item }
        setIsViewStatus(data);
    }

    const handleEditButtondata = (event: RowData[]) => {
        // console.log(event);

        setEditRowData(event);
    }



    const handlePagination = (event: any) => {
        // console.log(event);
        setPaginationData(event);
        // console.log(paginationData);


    }

    const handleDeleteButtondata = (event: any) => {
        // console.log(event);

        //setEditRowData(event);
    }




    useEffect(() => {
        const t1 = localStorage.getItem('isHidden');
        t1 == 'false' ? setFullWidth(false) : setFullWidth(true);

    }, [localStorage.getItem('isHidden')]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tableConfig, reportConfig, roleRightAccessSum] = await Promise.all([
                    userServicer.tableConfig(updatedPageID),
                    userServicer.reportConfig(updatedPageID),
                    RoleRightMasterService.getRoleRigthAccessSum(updatedPageID)
                ]);
                setColumnData(tableConfig.data);
                setreportConfigData(reportConfig.data);


                // setButtonSum(findSumElements(reportConfig.data.viewButtonsSum));
                setButtonSum(findSumElements(roleRightAccessSum.data[0].accessLevel)); //to implement AccessLevel according given Rights

                let def = reportConfig.data.defaultSearchList;
                // console.log("def" ,def.length);

                if (def.length > 0) {
                    let defaultSearch: any[] = [];

                    def.forEach((element: any) => {
                        if(element.hasFromToDateField == 1)
                        {
                            if(element.defaultSearchFieldValue != null && element.defaultSearchFieldValue != undefined)
                            {
                                const currentDate = new Date(element.defaultSearchFieldValue);
                                currentDate.setDate(currentDate.getDate() + element.defDateDurationInDays);
                                const nextDate = currentDate.toISOString().split('T')[0];
                                const today = new Date().toISOString().split('T')[0];
                                defaultSearch.push({ "key": element.defaultSearchField+"_from", "value": element.defaultSearchFieldValue })
                                defaultSearch.push({ "key": element.defaultSearchField+"_to", "value": nextDate > today ?  today : nextDate })
                            }else
                            {
                                const toDate = new Date().toISOString().split('T')[0];
                                const lastDate = new Date(toDate);
                                lastDate.setDate(lastDate.getDate() - element.defDateDurationInDays);
                                const fromDate = lastDate.toISOString().split('T')[0];
                                defaultSearch.push({ "key": element.defaultSearchField+"_from", "value": fromDate })
                                defaultSearch.push({ "key": element.defaultSearchField+"_to", "value": toDate })
                            }
                            
                        }
                        else
                        {
                            defaultSearch.push({ "key": element.defaultSearchField, "value": element.defaultSearchFieldValue })
                        }
                    });
                    //defaultSearch.push({ "key": def[0].defaultSearchField, "value": def[0].defaultSearchFieldValue });
                    //  console.log(defaultSearch);
                    setIsDefaultSearch(true);
                    setFilterFormData(defaultSearch);
                    setdefaultSeacrh(defaultSearch);
                }
                else {
                    let defaultSearch: any[] = [];
                    setIsDefaultSearch(false);
                    setFilterFormData(defaultSearch);
                    setdefaultSeacrh(defaultSearch);
                }
            } catch (error) {
                //console.error('Error fetching data:', error);
                navigate("/Pagenotfound")
            }
        };


        const findSumElements = (target: number): number[] => {
            //const arr = [1, 2, 4, 8, 16, 32, 64, 128];
            const arr = Object.values(AccessLevel);
            let result: number[] = [];
            let n = arr.length;

            // Use a variable to keep track of whether a valid subset is found
            let found = false;

            // Loop through all possible subsets using bitmasking
            for (let i = 0; i < (1 << n); i++) {
                let sum = 0;
                let subset: number[] = [];

                for (let j = 0; j < n; j++) {
                    if (i & (1 << j)) {
                        sum += arr[j];
                        subset.push(arr[j]);
                    }
                }

                // Check if the current subset sums up to the target
                if (sum === target) {
                    // Update result only if a subset is found
                    result = subset;
                    found = true;
                    break;  // Exit the loop once a valid subset is found
                }
            }

            // Return the result if found, otherwise an empty array
            return found ? result : [];
        };



        fetchData();

    }, [updatedPageID]);

    useEffect(() => {


        const fetchTableData = async () => {

            if (!reportConfigData) return;
            if (!filterFormData) return;
            if (!paginationData) return;

            try {

                const tabledatafromAPI = await userServicer.getReportlist(Number(reportConfigData.pageId), filterFormData, paginationData);
                setTableData(tabledatafromAPI.data);
                valueRef.current = tabledatafromAPI.data;
                setPageCount(tabledatafromAPI.data ? Math.ceil(tabledatafromAPI.data.length / perPage) : 1);
                const filteredColumnData = columnData.filter((item: any) => item.isDisplay === true);
                const exportColumnData = columnData.filter((item: any) => item.isExport === true);
                const filterColumArray = filteredColumnData.map(item => item.tableFieldName).filter(Boolean);
                const updatedfilteredData = tabledatafromAPI.data.map((item: any) =>
                    Object.keys(item)
                        .filter(key => filterColumArray.includes(key))
                        .reduce((obj: any, key: any) => {
                            obj[key] = item[key];
                            return obj;
                        }, {})
                );
                settableColumns(filteredColumnData);
                setExportColumns(exportColumnData);
                setCSVData(updatedfilteredData);
            } catch (error) {

            }
        };

        let x1 = typeof reportConfigData
         //console.log(reportConfigData.reportName);

        if (reportConfigData?.reportName != undefined && reportConfigData?.pageId == updatedPageID) {

            fetchTableData();
        }

    //}, [updatedPageID,reportConfigData, filterFormData, refreshData, paginationData]);
}, [reportConfigData, filterFormData, refreshData, paginationData]);




    return (
        <>

            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;{reportConfigData.reportName}</span>
                        {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> */}
                    </div>
                    <hr className="colorgraph" />
                </div>

                <div className="mani2">
                    {updatedPageID == PageEnums.Designation && <AddDesignation columndata={tableColumns} editRowData={editRowData ? editRowData : ''} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus} />}
                </div>
                <div className="mani2">
                    {updatedPageID == PageEnums.State && <AddState columndata={tableColumns} editRowData={editRowData ? editRowData : ''} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus} />}
                </div>
                <div className="mani2">
                    {updatedPageID == PageEnums.City && <AddCity columndata={tableColumns} editRowData={editRowData ? editRowData : ''} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus} />}
                </div>
                <div className="mani2">
                    {
                        updatedPageID == PageEnums.AreaList && <AddArea columndata={tableColumns} editRowData={editRowData ? editRowData : ''} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus} />
                    }
                </div>

                <div className="mani"> {reportConfigData.length === undefined ? (<EnhancedSearchbar pageID={updatedPageID} buttonSum={viewButtonSum} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={defaultSeacrh1} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={exportColumns} />) : (updatedPageID === String(reportConfigData.pageId) && (<EnhancedSearchbar pageID={updatedPageID} buttonSum={viewButtonSum} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={defaultSeacrh1} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={exportColumns} />))}</div>
                {/* To Prevent Different Routing */}
                <div className="card-container" >

                    {
                        <EnhancedTable pageID={updatedPageID} tableHeight={updatedPageID == PageEnums.AreaList ? '280px' : updatedPageID == PageEnums.CollectionReport ? '350px' : '350px'} buttonSum={viewButtonSum} columnData={columnData} tableDatafromParent={valueRef.current} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} deleteEvent={handleDeleteButtondata} deleteConformationMsg={reportConfigData.reportName} historyQuery={reportConfigData} historyColumnParameter={null} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }}/> // Jignesh : add deleteConformationMsg to Add custom msg for Delete pop up
                    }


                </div>
            </div>
        </>
    );
}

export default EnhancedPage;




