import * as React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import { visuallyHidden } from '@mui/utils';
import { ThemeProvider, Tooltip, Typography, createTheme, styled, useTheme } from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useParams } from 'react-router-dom';
import { Download, Edit, History, OpenInNew } from '@mui/icons-material';
import { IoEye } from 'react-icons/io5';
import Popup from 'reactjs-popup';
import PageNotFound from '../../pages/PageNotFound';
import load from '../../assets/Images/dots.gif';
import customToast from './Toast/CustomToast';
import { AccessLevel, InputType, MasterPrimaryKeys, PageEnums } from '../../_enums/enum.services';
import CollectionReport from '../CollectionReport/CollectionReport';
import { ClockIcon } from '@mui/x-date-pickers';
import { userServicer } from '../Commen_Services/user.services';
import ErrorDialog from './ErrorContainer/ErrorDialog';
import ErrorIcon from '@mui/icons-material/Error';
import SyncIcon from '@mui/icons-material/Sync';

interface ColumnData {
  tableFieldName: string;
  headerName: string;
}

interface RowData {
  [key: string]: any;
}

interface propsHead {
  pageID: string;
  buttonSum: Number[];
  columnData: ColumnData[];
  tableDatafromParent: RowData[];
  sendDataToParent: (event: RowData[]) => void;
  sendPaginationDatatoParent: any;
  handleViewStatus: any;
  tableHeight: any;
  deleteEvent: any;
  deleteConformationMsg: any; // to pass custom msg in delete button
  historyQuery: any;
  historyColumnParameter: any;
  refreshGrid : any;
}

function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order: Order, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  //console.log(array);

  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

// interface PaginationDataforParent {
//   pageSize: Number;
//   pagenumber: Number;
// }

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const tableTheme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {

          lineHeight: '5px',
        }
      }
    }
  }
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    height: "5px"
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#B3C9E6',
    color: 'var(--body-main-dark-color)',


  },
  // hide last border
  '& td, &:last-child th': {
    border: 0,
    backgroundColor: '#B3C9E6',
    color: 'var(--body-main-dark-color)',
    height: '5px',
    fontSize: '12px',
    fontWeight: '700'

  },
}));







function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


const EnhancedTable: React.FC<propsHead> = ({ pageID, tableHeight, buttonSum, columnData, tableDatafromParent, sendDataToParent, sendPaginationDatatoParent, handleViewStatus, deleteEvent, deleteConformationMsg, historyQuery, historyColumnParameter, refreshGrid }) => {


  const { paramPageId } = useParams();
  let PageNumber = pageID || paramPageId;
  const [open, setOpen] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('id');
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [isView, setIsView] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [paginationdata, setPaginationData] = React.useState({});
  const [tableColumns, settableColumns] = React.useState<RowData[]>(columnData);
  const [tableData, setTableData] = React.useState<RowData[]>(tableDatafromParent);
  const [loader, setLoader] = React.useState(true);
  const [loaderSeconds, setLoaderSeconds] = React.useState<number>(60);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [countView, setCountView] = React.useState(0);
  const [countEdit, setCountedit] = React.useState(0);
  const [secondsforView, setSecondsforView] = React.useState<number>(0);
  const [secondsforEdit, setSecondsforEdit] = React.useState<number>(0);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [tableRowData, setTableRowData] = React.useState<any>({});
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const collectionData = React.useRef({});
  const [showAction, setShowAction] = React.useState(false);
  const excludedValues = new Set([0, 16, 32, 64, 2048, 2160]);
  const [filterRowData, setFilterRowData] = React.useState<any>({});
  const [historyList, setHistoryList] = React.useState([]);
  const [historyTableConfig, setHistoryTableConfig] = React.useState<ColumnData[]>([]);
  const [errorDiaLog, setErrorDialog] = React.useState<boolean>(false);
  const errorData = React.useRef({});

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClick = (data: any) => {
    deleteEvent(data);
  }

  const handleEditButtonData = (data: RowData) => {
    setCountView(1);
    setSecondsforView(4);
    if (isView === true) {
      if (countView === 0) {
        customToast.Warning('View Task is running');
      }
    }
    else {
      setIsEdit(true);
      data.isViewData = false;
      sendDataToParent(data as RowData[]);
    }


  }

  React.useEffect(() => {
    setShowAction(buttonSum.some((item:any) => !excludedValues.has(item)));
  }, [buttonSum]);

  const handleEditNewButtonData = (data: any) => {
    let url = '/';
    let keyName = null;
    let enumkey = Object.values(PageEnums).filter((item: any) => item === Number(PageNumber));
    if (enumkey !== null || enumkey !== undefined) {
      for (const key in PageEnums) {
        if (Object.prototype.hasOwnProperty.call(PageEnums, key)) {
          if (PageEnums[key as keyof typeof PageEnums] === Number(enumkey)) {
            keyName = key;
            break;
          }
        }
      }


      // url = String("Add").concat(String(keyName)).concat("/Edit");
      let primaryKey = "";
      if (keyName !== null) {
        if (keyName in MasterPrimaryKeys) {
          primaryKey = MasterPrimaryKeys[keyName as keyof typeof MasterPrimaryKeys];
        }
      }
      url = String("/Master/").concat(String(keyName)).concat("/").concat(String(data[primaryKey]));
      //data.isEditData = true;
      navigate(url);
      //navigate(url, { state: data });

    }
    else {
      url = String("Add").concat(String(keyName));
    }

  }

  const handleViewNewButtonData = async (data: any) => {

    let url = '/';
    let keyName = null;
    let enumkey = Object.values(PageEnums).filter((item: any) => item === Number(PageNumber));
    if (enumkey !== null || enumkey !== undefined) {
      for (const key in PageEnums) {
        if (Object.prototype.hasOwnProperty.call(PageEnums, key)) {
          if (PageEnums[key as keyof typeof PageEnums] === Number(enumkey)) {
            keyName = key;
            break;
          }
        }
      }

      let primaryKey = "";
      if (keyName !== null) {
        if (keyName in MasterPrimaryKeys) {
          primaryKey = MasterPrimaryKeys[keyName as keyof typeof MasterPrimaryKeys];
        }
      }


      // url = String("Add").concat(String(keyName)).concat("/View");
      url = String("/Master/").concat(String(keyName)).concat("/view/").concat(String(data[primaryKey]));
      navigate(url);
      //data.isViewData = true;
      //navigate(url, { state: data });

    }
    else {
      url = String("Add").concat(String(keyName));
    }




  }

  const handleViewButtonData = (data: RowData) => {

    data.isViewData = true;
    setCountedit(1);
    setSecondsforEdit(4);
    if (isEdit === true) {
      if (countEdit === 0) {
        customToast.Warning('Edit Task is running');
      }
    }
    else {
      setIsView(true);
      sendDataToParent(data as RowData[]);
    }

  }

  const handleHistoryButton = async (data: any) => {
    setOpen(true);
    //console.log(data, 'data-action');
    //console.log(historyQuery?.historyReportColumnParameter, 'data');

    const filterField = historyQuery?.historyReportColumnParameter;
    const x = filterData(data, filterField);
    //console.log(x, 'data');
    const tabledatafromAPI = await userServicer.getHistorylist(Number(pageID), x);
    const historyTableColumn = await userServicer.historyTableConfig(Number(pageID));
    //console.log(tabledatafromAPI);
    //console.log(historyTableColumn);
    // historyTableColumn?.data.map((item: any) => {

    // });
    setHistoryTableConfig(historyTableColumn?.data);
    setHistoryList(tabledatafromAPI?.data);
    //historyList.current = tabledatafromAPI?.data;


  }




  const DynamicHistoryTable = React.useCallback(() => {
    // Dynamically extract column headers from the first row of data
    const data = historyList;
    //console.log(data, "data");
    //const headers = Object.keys(data[0]);
    if (historyList?.length > 0 && historyTableConfig?.length > 0 && open) {
      return (
        <div
          style={{
            maxHeight: '50vh',
            overflowY: 'auto',
            border: '1px solid #ddd',
          }}>
          <table cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>
            <thead>
              <tr
                //style={{backgroundColor: 'var(--body-main-light-color)'}}
                style={{
                  border: '1px solid #ddd',
                  padding: '2px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: 'var(--body-main-dark-color)',
                  position: 'sticky',
                  top: -2, // Makes the header stick at the top
                  backgroundColor: 'var(--body-main-light-color)', // Ensures the background remains visible when scrolling
                  zIndex: 1,
                }}

              >
                {historyTableConfig.map((header) => (
                  <th key={header.tableFieldName} style={{ border: '1px solid #ddd', padding: '2px', fontSize: '12px', fontWeight: 'bold', color: 'var(--body-main-dark-color)' }}>{header.headerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row: any, index: any) => (
                <tr key={index}>
                  {historyTableConfig.map((header) => (
                    <td key={header.tableFieldName} style={{ border: '1px solid #ddd', padding: '3px', fontSize: '13px', color: 'var(--sidebar-font-color)' }}>
                      {row[header.tableFieldName] !== null ? row[header.tableFieldName] : 'N/A'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }, [open, historyList, historyTableConfig]);


  const tempHistoryData = React.useMemo(() => {
    if (historyList?.length > 0 && historyTableConfig?.length > 0 && open) {
      return DynamicHistoryTable();
    }

  }, [historyList, historyTableConfig])



  const filterData = (data: any, filterField: string): any[] => {
    // Split the filterField string into an array of keys
    const fields = filterField?.split(',');

    // Map the fields to an array of key-value pairs
    return fields ? fields.map(field => ({
      key: field,
      value: data[field]
    })) : [];
  };

  const handleActionButton = async (data: any) => {

    let url = '/';
    let keyName = null;
    let enumkey = Object.values(PageEnums).filter((item: any) => item === Number(PageNumber));
    if (enumkey !== null || enumkey !== undefined) {
      for (const key in PageEnums) {
        if (Object.prototype.hasOwnProperty.call(PageEnums, key)) {
          if (PageEnums[key as keyof typeof PageEnums] === Number(enumkey)) {
            keyName = key;
            break;
          }
        }
      }

      let primaryKey = "";
      if (keyName !== null) {
        if (keyName in MasterPrimaryKeys) {
          primaryKey = MasterPrimaryKeys[keyName as keyof typeof MasterPrimaryKeys];
        }
      }


      // url = String("Add").concat(String(keyName)).concat("/View");
      url = String("/Master/").concat(String(keyName)).concat("/view/").concat(String(data[primaryKey]));
      //navigate(url);
      //data.isViewData = true;
      navigate(url, { state: data });

    }
    else {
      url = String("Add").concat(String(keyName));
    }




  }

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleConfirmExit = () => {

    handleDeleteClick(tableRowData);
    setShowConfirmation(false);
  };

  const handleDownloadButton = (data: any) => {
    const filePath = data.requestFilePath;
    if(filePath != null && filePath != undefined)
    {
      const link = document.createElement("a");
      link.download = filePath.split('/').pop() || 'download';
      window.open(filePath, '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleErrorLogButton = React.useCallback((data: any) => {
    
    if(data?.errorLog != null && data?.errorLog != undefined)
    {
      errorData.current = data;
      setErrorDialog(true);
    }   
  }, [errorDiaLog])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (secondsforView > 0) {
        setSecondsforView(secondsforView - 1);
      }
    }, 1000);

    if (secondsforView === 0) {

      setCountView(0);
    }
    return () => clearInterval(interval);
  }, [secondsforView]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (secondsforEdit > 0) {
        setSecondsforEdit(secondsforEdit - 1);
      }
    }, 1000);

    if (secondsforEdit === 0) {
      setCountedit(0);
    }

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [secondsforEdit]);

  React.useEffect(() => {
    setTableData(tableDatafromParent);



    tableDatafromParent != null && tableDatafromParent.length > 0 && setLoader(false)
    // tableDatafromParent.length > 0 ? setLoader(false) : (tableDatafromParent as any).length == 0 ? setLoader(false) : setLoader(true);

    let updatedDatafromParent = tableDatafromParent?.map((item: any) => item.totalRecord);
    updatedDatafromParent ? setTotalRecord(updatedDatafromParent[0]) : setTotalRecord(0);
    const filteredColumnData = columnData !== undefined && columnData.filter((item: any) => item.isDisplay === true);
    settableColumns(filteredColumnData as RowData[]);


  }, [tableDatafromParent, columnData, buttonSum]);

  React.useEffect(() => {
    setPage(0)
  }, [totalRecord])

  React.useEffect(() => {
    setPaginationData({ pageSize: rowsPerPage, pageNumber: page + 1 })

  }, [page, rowsPerPage])

  React.useEffect(() => {
    sendPaginationDatatoParent(paginationdata);
  }, [paginationdata])

  React.useEffect(() => {
    if (handleViewStatus.isViewStatus === false) {
      setIsView(false);
      setIsEdit(false);
    }

  }, [handleViewStatus])

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <ThemeProvider theme={tableTheme}>

        <TableHead sx={{ '& th': { height: '10px' } }}>
          <StyledTableRow>
            {tableColumns.map((column) => (
              <TableCell
                key={column.tableFieldName}
                align={column.numeric ? 'right' : 'left'}
                sortDirection={orderBy === column.tableFieldName ? order : false}
                style={{ paddingTop: 3, paddingBottom: 3, }}
              >
                <TableSortLabel
                  active={orderBy === column.tableFieldName}
                  direction={orderBy === column.tableFieldName ? order : 'asc'}
                  onClick={createSortHandler(column.tableFieldName)}
                  style={{ paddingTop: 0, paddingBottom: 0, }}
                >
                  {column.headerName}
                  {orderBy === column.tableFieldName ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            {/* To Add Action column name  */}
            {/* <TableCell style={{ paddingTop: 3, paddingBottom: 3, }}>{tableColumns.length > 0 ? <>Action</> : <>&nbsp;</>}</TableCell> */}
            {dialogAction}

          </StyledTableRow>
        </TableHead>

      </ThemeProvider>
    );
  }

  const dialogAction = React.useMemo(() => (
    showAction ? <TableCell style={{ paddingTop: 3, paddingBottom: 3, }}>{tableColumns.length > 0 ? <>Action</> : <>&nbsp;</>}</TableCell> : <TableCell style={{ paddingTop: 3, paddingBottom: 3, }}></TableCell>
  ), [showAction]);


  //Jignesh :  To solve issue for  Loader when no data found from the aPI but loader is excuting 
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (loaderSeconds > 0) {
        setLoaderSeconds(loaderSeconds - 1);
      }
    }, 1000);

    if (loaderSeconds === 0) {
      setLoader(false);

    }
    return () => clearInterval(interval);
  }, [loaderSeconds]);

  // tableData && (tableData[0] !== undefined && console.log(tableData[0]['totalRecord']));

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const handleSubReport = React.useCallback((data: any) => {
    collectionData.current = data;
    setDialogOpen(true);
  }, [dialogOpen])

  const dialogCollection = React.useMemo(() => {
    return (
      <CollectionReport CollectionData={collectionData.current} open={dialogOpen} onClose={handleDialogClose}/>
    )
  }, [dialogOpen]);

  const filtercoluumnData = async (data: any, rowData: any) => {
    debugger
    const pairs = data.split(',');
    const result: { [key: string]: string } = {};
    const headerReasult: { [key: string]: string } = {};
    if(pairs !== undefined && pairs !== null && pairs !== '')
    {
      pairs.forEach((pair: { split: (arg0: string) => [any, any]; }) => {
        const [key, value] = pair.split('=');
        if(value !== undefined)
        {
          result[key.trim()] = value.replace(/'/g, '').trim();
        }
      });
    }

    if(rowData !== null && rowData !== undefined && rowData !== '')
    {
      console.log(rowData.reportPageID);
      const reportColumns = await userServicer.tableConfig(Number(rowData.reportPageID));
      reportColumns.data.forEach((column: any) => {
        const fieldName = column.tableFieldName;
        const headerName = column.headerName;
        if(column.fieldType == InputType.Date || column.fieldType == InputType.DateTime)
        {
          if (result.hasOwnProperty(fieldName + '_from')) {
            headerReasult[headerName + ' From'] = result[fieldName + '_from'];
          }
          if (result.hasOwnProperty(fieldName + '_to')) {
            headerReasult[headerName + ' To '] = result[fieldName + '_to'];
          }
        }
        else
        {
          if (result.hasOwnProperty(fieldName)) {
            headerReasult[headerName] = result[fieldName];
          }
        }
      });
    }
    
   const formattedContent = Object.entries(headerReasult).map(([key, value]) => (
    <div key={key} style={{marginTop : '1px'}}>
      {key.replace(/_/g, ' ')} : {value}
    </div>
  ));

    setFilterRowData(formattedContent); 

  }

  const handleErrorPopupClose = () => {
    
    setErrorDialog(false);
    refreshGrid(true);
    
  }
  const errorDialogBox = React.useMemo(() => {
    return (
      <>
        <Popup contentStyle={{ height: '50%', width: '25%', padding: 0 }} position='top center' open={errorDiaLog} onClose={() => setErrorDialog(false)}>
          <ErrorDialog errorData= {errorData.current} onPopupClose={handleErrorPopupClose} /> 
        </Popup>
      </>
    )
  }, [errorDiaLog]);


  return (
    <>
      <Paper sx={{ width: '100%', height: '65vh', marginBottom: 2 }}>
        {/* <Paper sx={{ width: '100%', marginBottom: 2 }}> */}
        <TableContainer sx={{ maxHeight: `${tableHeight}` }}>

          {/* <TableContainer > */}
          <Table
            sx={{ minWidth: 50, overflowY: 'auto', }}
            aria-labelledby="tableTitle"
            stickyHeader aria-label="sticky table"

          >

            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />


            <TableBody>
              {

                loader ?
                  <>
                    <TableRow  >
                      <TableCell colSpan={tableColumns.length} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 8 }} >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={load} height="300px" alt="Empty State" />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                  : tableData?.length > 0 ? stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        style={{ height: 2, fontSize: 10 }}
                      >{
                          tableColumns.map((column) => {
                            if (column.fieldType === 7) {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                  {row[column.tableFieldName] === true ? <><input type='checkbox' checked readOnly /></> : <><input type='checkbox' readOnly /></>}
                                </TableCell>

                              );
                            }
                            else if (column.fieldType === 13) {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 5, paddingBottom: 5, fontSize: 12, color: 'blueviolet', cursor: 'pointer' }} align={column.numeric ? 'right' : 'left'}
                                  onClick={(event) => { event.stopPropagation(); handleSubReport(row); }}>
                                  {row[column.tableFieldName]}
                                </TableCell>

                              );
                            }
                            else if (column.fieldType === 15) {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 5, paddingBottom: 5, fontSize: 12, color: 'blueviolet', cursor: 'pointer' }} align={column.numeric ? 'right' : 'left'} onMouseEnter={() => {filtercoluumnData(row[column.tableFieldName], row)}}>
                                  {/* {row[column.tableFieldName]} */}
                                  <Tooltip title={filterRowData}>
                                    <IconButton>
                                      <IoEye />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            }
                            else if (column.fieldType === 16) {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 5, paddingBottom: 5, fontSize: 12, color: 'blueviolet', cursor: 'pointer' }} align={column.numeric ? 'right' : 'left'}  >
                                  {/* {row[column.tableFieldName]} */}

                                  {row[column.tableFieldName] == 1 ? 'Success': (
                                    <button className='resync-btn1' onClick={(event) => { event.stopPropagation(); handleErrorLogButton(row); }}><span className="error-btn"><SyncIcon />&nbsp;Re-Sync</span></button>
                                    )}
                                    
                                </TableCell>
                              );
                            }
                            else if (column.fieldType === 6 ) {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                  {row[column.tableFieldName] != null && new Date(row[column.tableFieldName]).toLocaleDateString('en-GB',{
                                                                                                                        day: '2-digit',
                                                                                                                        month: '2-digit',
                                                                                                                        year: 'numeric'
                                                                                                                        })}
                                </TableCell>
                              );
                            }
                            else if (column.fieldType === 12 ) {

                              const newDate = new Date(row[column.tableFieldName])
                              // console.log(newDate,'newDate')
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                  {row[column.tableFieldName] != null && newDate?.toString() != 'Invalid Date' ? newDate.toLocaleDateString('en-GB',{
                                                                                                                        day: '2-digit',
                                                                                                                        month: '2-digit',
                                                                                                                        year: 'numeric',
                                                                                                                        hour: '2-digit',
                                                                                                                        minute: '2-digit',
                                                                                                                        hour12: true,
                                                                                                                        }) : row[column.tableFieldName]}
                                </TableCell>
                              );
                            }
                            else {
                              return (
                                <TableCell key={`${row.id}-${column.id}`} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                  {row[column.tableFieldName]}
                                </TableCell>
                              );
                            }

                          })
                        }
                        <TableCell style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 8 }} >
                          {


                            buttonSum.map((item: any, itemIndex) => (
                              <React.Fragment key={itemIndex}>

                                {item === AccessLevel.View && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); secondsforEdit === 0 && handleViewButtonData(row); }}><IoEye /></IconButton>)}
                                {item === AccessLevel.Edit && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); secondsforView === 0 && handleEditButtonData(row); }}><Edit /></IconButton>)}
                                {item === AccessLevel.EditNew && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); handleEditNewButtonData(row); }}><Edit /></IconButton>)}
                                {item === AccessLevel.ViewNew && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); handleViewNewButtonData(row); }}><IoEye /></IconButton>)}
                                {item === AccessLevel.ViewHistory && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); handleHistoryButton(row); }}><History /></IconButton>)}
                                {item === AccessLevel.Delete && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); setTableRowData(row); setShowConfirmation(true); }}><DeleteIcon /></IconButton>)}
                                {item === AccessLevel.Download && ((row.requestFilePath != null || row.requestFilePath != undefined) ? (row.requestFilePath === "" ? <></> : <IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); handleDownloadButton(row); }}><Download /></IconButton>) : <span style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} >Expired</span>)}
                                {item === AccessLevel.Action && (<IconButton aria-label="select" sx={{ color: 'var(--body-main-dark-color)' }} onClick={(event) => { event.stopPropagation(); handleActionButton(row); }}><OpenInNew /></IconButton>)}
                              </React.Fragment>
                            ))
                          }
                        </TableCell>

                      </TableRow>
                    ))
                    :
                    <TableRow  >
                      <TableCell colSpan={tableColumns.length} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 8 }} >
                        <PageNotFound />
                      </TableCell>
                    </TableRow>


              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData ? (tableData[0] !== undefined ? tableData[0]['totalRecord'] : 0) : 0} // to remove false and Nan if no data found
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <Popup contentStyle={{ maxHeight: "70%", width: "70%", padding: 0 }} open={open} onClose={() => { setOpen(false); }}  >
          <div className="shadow">
            <div className="bg-line">
              <span className="bg-head" style={{ display: 'flex', justifyContent: 'center' }}><ClockIcon /> &nbsp;History</span>
              {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> */}
            </div>
            <hr className="colorgraph" />
          </div>
          <div style={{ padding: 10 }} >
            {tempHistoryData}
          </div>

        </Popup>
      </Paper>

      {showConfirmation && (
        <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
          <div className="confirmation-modal">
            <p>Are you sure you want to delete this {deleteConformationMsg} ?</p>
            <div className="canel_btns">
              <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
              <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
            </div>
          </div>
        </Popup>
      )}

      {errorDiaLog && (
          <>
          {errorDialogBox}
          </>
        )}
      
      {dialogOpen && (
        <>
          {dialogCollection}
        </>
      )}
    </>
  );
}

export default EnhancedTable;
